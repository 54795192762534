import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchNotifications, updateNotificationStatus } from '../util/http'; // Replace with your API methods
import { useUser } from './UserContext';

interface Notification {
  id: string;
  type: string;
  status: string;
  created_at: string;

  [key: string]: any; // Add additional fields as necessary
}

interface NotificationsContextType {
  notifications: Notification[];
  hasUnread: boolean;
  fetchAllNotifications: () => void;
  markAsRead: (notificationId: string) => void;
  acceptInvite: (notificationId: string) => void;
  ignoreInvite: (notificationId: string) => void;
}

const NotificationsContext = createContext<NotificationsContextType | undefined>(undefined);

export const NotificationsProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUser();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [hasUnread, setHasUnread] = useState(false);

  useEffect(() => {
    if (user?.uid) {
      fetchAllNotifications();
    }
  }, [user]);

  const fetchAllNotifications = async () => {
    try {
      const fetchedNotifications = await fetchNotifications({ uid: user?.uid, signal: null, max: null });
      setNotifications(fetchedNotifications);
      setHasUnread(fetchedNotifications.some((n: any) => n.status === 'PENDING' ));
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const markAsRead = async (notificationId: string) => {
    try {
      await updateNotificationStatus({ uid: user?.uid, notificationId, action: 'read' });
      await fetchAllNotifications();
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const acceptInvite = async (notificationId: string) => {
    try {
      await updateNotificationStatus({ uid: user?.uid, notificationId, action: 'accept' });
      await fetchAllNotifications();
    } catch (error) {
      console.error('Error accepting invite:', error);
    }
  };

  const ignoreInvite = async (notificationId: string) => {
    try {
      await updateNotificationStatus({ uid: user?.uid, notificationId, action: 'ignore' });
      await fetchAllNotifications();
    } catch (error) {
      console.error('Error ignoring invite:', error);
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        hasUnread,
        fetchAllNotifications,
        markAsRead,
        acceptInvite,
        ignoreInvite,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }
  return context;
};
