import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useUser } from '../store/UserContext';
import {
  Bars2Icon,
  BellIcon,
  BookOpenIcon,
  HomeIcon,
  TagIcon,
  UsersIcon,
  XCircleIcon,
  BookmarkIcon,
} from '@heroicons/react/24/outline';

import AvatarPlaceholder from './UI/AvatarPlaceholder';
import Button from './UI/Button';
import { useNotifications } from '../store/NotificationsContext';
import { Menu, Transition } from '@headlessui/react';
import AddNewButton from './UI/AddNewButton';

export default function Header() {
  const navigate = useNavigate();
  const { user, signOut } = useUser(); // Destructure signOut directly
  const { notifications, hasUnread } = useNotifications();
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const [isBouncing, setIsBouncing] = useState(false); // Track bounce animation
  const registeredUser = user ? user.username : false;

  const iconClasses =
    'p-3 flex rounded-full hover:border-gray-300 bg-gray-50 hover:bg-white hover:shadow-lg transition-all';

  const handleLogout = () => {
    signOut();
    navigate('/');
  };

  const handleNotificationsClick = () => {
    // Stop bounce animation on click
    setIsBouncing(false);
  };

  useEffect(() => {
    if (hasUnread) {
      setIsBouncing(true); // Start bounce animation when new notifications arrive
    }
  }, [hasUnread]);

  // Redirect user to "Create New Profile"
  useEffect(() => {
    // Gmail Auth
    if (user?.email && user?.username === undefined && user?.app_metadata.provider !== 'email') {
      navigate(
        `/users/new/?uid=${user?.id}&username=${
          user?.user_metadata.full_name
            // slugify
            .toLowerCase()
            .trim()
            .replace(/[\s\W-]+/g, '-')
            .replace(/^-+|-+$/g, '') ?? ''
        }&name=${user?.user_metadata.full_name ?? ''}&email=${user?.email ?? ''}&image=${
          user?.user_metadata.avatar_url ?? ''
        }`
      );
    }

    // Email Auth
    else if (user && user.username === undefined) {
      navigate(`/users/new/?email=${user.email}&uid=${user?.id}`);
    }
  }, [user, navigate]);

  return (
    <>
      <header className="fixed z-20 top-0 w-full px-2 sm:px-7 flex items-center justify-between text-gray-700 border-t border-white bg-gray-50 lg:bg-transparent min-h-[5rem]">
        <div className="flex items-center gap-4">
          <div className="flex gap-4">
            <button onClick={() => setSideMenuIsOpen(true)} aria-label="Open Menu" className="p-4 pl-5 -m-4">
              <Bars2Icon
                className="size-6"
                style={{
                  filter:
                    'drop-shadow(1px 1px 0 white) drop-shadow(-1px -1px 0 white) drop-shadow(-1px 1px 0 white) drop-shadow(1px -1px 0 white)',
                }}
              />
            </button>
            {registeredUser ? (
              <div className="ps-2">
                <AddNewButton />
              </div>
            ) : (
              <>
                <NavLink to="/" className={user ? 'hidden sm:block' : ''}>
                  <h1 className="font-bold text-2xl">Bokkah</h1>
                </NavLink>
              </>
            )}
          </div>
        </div>

        <menu className={`flex ${user ? 'gap-0 xl:gap-1 relative top-[0.1333333rem]' : 'gap-8'} items-center`}>
          {registeredUser && (
            <>
              {/* <li>
                <NavLink to={`/users/${user.username}/friends/`} className={iconClasses}>
                  <UsersIcon className="size-6" />
                </NavLink>
              </li> */}
              <li className="me-2">
                <NavLink
                  to="/dashboard/notifications/"
                  className={iconClasses + ' relative'}
                  onClick={handleNotificationsClick}
                >
                  {hasUnread && (
                    <span
                      className={`${
                        isBouncing ? 'animate-bounce' : ''
                      } absolute right-0 -top-1 text-white bg-gray-900 rounded-xl py-0.5 px-1.5 text-sm font-extrabold tracking-tighter text-center`}
                    >
                      {notifications.filter((notification) => notification.status === 'PENDING').length > 99
                        ? '99+'
                        : notifications.filter((notification) => notification.status === 'PENDING').length}
                    </span>
                  )}
                  <BellIcon className="size-6" />
                </NavLink>
              </li>
              <li className="shrink-0">
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button className="rounded-full overflow-hidden shadow hover:shadow-lg transition-all border-2 border-white">
                    {user?.image ? (
                      <img src={user?.image} alt={`${user?.name}'s avatar`} className="size-[3.25rem] bg-skeleton" />
                    ) : (
                      <AvatarPlaceholder />
                    )}
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute z-[1] right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                      <div className=" z-20 right-8 top-24 m-w-40 rounded-lg border border-gray-300 bg-white shadow-lg transition-all overflow-hidden">
                        <div className="py-3 px-5 block w-full border-b mb-2">
                          <span className="font-medium block text-ellipsis overflow-hidden whitespace-nowrap">
                            {user.name}
                          </span>
                          <span className="block text-gray-500 text-ellipsis overflow-hidden whitespace-nowrap">
                            {user.email}
                          </span>
                        </div>
                        {registeredUser ? (
                          <>
                            <Menu.Item>
                              {({ close: dismiss }) => (
                                <div className="mt-2">
                                  <NavLink
                                    to={`dashboard/`}
                                    className="py-2 px-5 block w-full text-left font-semibold hover:bg-gray-100 text-sm"
                                    onClick={dismiss}
                                  >
                                    Dashboard
                                  </NavLink>
                                </div>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ close: dismiss }) => (
                                <div>
                                  <NavLink
                                    to={`/users/${user.username}/`}
                                    className="py-2 px-5 block w-full text-left font-semibold hover:bg-gray-100 text-sm"
                                    onClick={dismiss}
                                  >
                                    Profile
                                  </NavLink>
                                </div>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ close: dismiss }) => (
                                <div>
                                  <NavLink
                                    to={`/users/${user.username}/recipes/`}
                                    className="py-2 px-5 block w-full text-left font-semibold hover:bg-gray-100 text-sm"
                                    onClick={dismiss}
                                  >
                                    Recipes
                                  </NavLink>
                                </div>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ close: dismiss }) => (
                                <div>
                                  <NavLink
                                    to={`/users/${user.username}/collections/`}
                                    className="py-2 px-5 block w-full text-left font-semibold hover:bg-gray-100 text-sm"
                                    onClick={dismiss}
                                  >
                                    Collections
                                  </NavLink>
                                </div>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ close: dismiss }) => (
                                <div className="mb-2 border-b-2">
                                  <NavLink
                                    to={`/users/${user.username}/friends/`}
                                    className="py-2 px-5 block w-full text-left font-semibold hover:bg-gray-100 text-sm mb-2"
                                    onClick={dismiss}
                                  >
                                    Friends
                                  </NavLink>
                                </div>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ close: dismiss }) => (
                                <div className="mt-2">
                                  <NavLink
                                    to={`users/${user.username}/edit/`}
                                    className="py-2 px-5 block w-full text-left font-semibold hover:bg-gray-100 text-sm"
                                    onClick={dismiss}
                                  >
                                    Edit Account
                                  </NavLink>
                                </div>
                              )}
                            </Menu.Item>
                          </>
                        ) : (
                          <>
                            <Menu.Item>
                              {({ close: dismiss }) => (
                                <div className="mt-2">
                                  <NavLink
                                    to={`users/${user.username}/edit/`}
                                    className="py-2 px-5 block w-full text-left font-semibold hover:bg-gray-100 text-sm"
                                    onClick={dismiss}
                                  >
                                    Create Your Profile
                                  </NavLink>
                                </div>
                              )}
                            </Menu.Item>
                          </>
                        )}
                        <Menu.Item>
                          <div className="mb-2">
                            <button
                              className="py-2 px-5 flex gap-2 justify-between items-center w-full text-left font-semibold hover:bg-red-50 text-sm"
                              onClick={handleLogout}
                            >
                              Sign out
                            </button>
                          </div>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </li>
            </>
          )}
          {!user && (
            <>
              <li>
                <Button isPrimary to="/login" className="py-3">
                  Sign in
                </Button>
              </li>
            </>
          )}
        </menu>
      </header>

      <div
        className={`w-full h-full fixed  ${
          sideMenuIsOpen ? 'backdrop-blur ' : 'backdrop-blur-0 pointer-events-none'
        } z-20 top-0 left-0 transition-all `}
        onClick={() => setSideMenuIsOpen(false)}
      />
      <aside
        className={`bg-gray-700 text-gray-50 w-full h-full fixed backdrop-blur-sm z-20 top-0 left-0 transition-transform transform max-w-80 shadow-2xl ${
          sideMenuIsOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="px-4 py-8">
          <button
            onClick={() => setSideMenuIsOpen(false)}
            className="w-full flex gap-2 py-8 -my-8 pe-12 pl-4 -ml-4 sm:ml-0 sm:-my-9 sm:pb-9"
          >
            <XCircleIcon className="w-6 h-6" /> <span className="px-2">Close menu</span>
          </button>
        </div>
        <menu className="border-t border-t-gray-500 pt-2 border-b border-b-gray-500 pb-2">
          <li>
            <NavLink
              to={'/'}
              className="px-4 py-8 sm:px-8 text-left font-bold hover:bg-gray-600 flex gap-4"
              onClick={() => setSideMenuIsOpen(false)}
            >
              <HomeIcon className="w-6 h-6" />
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/recipes/'}
              className="px-4 py-8 sm:px-8 text-left font-bold hover:bg-gray-600 flex gap-4"
              onClick={() => setSideMenuIsOpen(false)}
            >
              <BookOpenIcon className="w-6 h-6" /> Recipes
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/categories/'}
              className="px-4 py-8 sm:px-8 text-left font-bold hover:bg-gray-600 flex gap-4"
              onClick={() => setSideMenuIsOpen(false)}
            >
              <TagIcon className="w-6 h-6" /> Categories
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/collections/'}
              className="px-4 py-8 sm:px-8 text-left font-bold hover:bg-gray-600 flex gap-4"
              onClick={() => setSideMenuIsOpen(false)}
            >
              <BookmarkIcon className="w-6 h-6" /> Collections
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/users/'}
              className="px-4 py-8 sm:px-8 text-left font-bold hover:bg-gray-600 flex gap-4"
              onClick={() => setSideMenuIsOpen(false)}
            >
              <UsersIcon className="w-6 h-6" /> People
            </NavLink>
          </li>
        </menu>
      </aside>
    </>
  );
}
