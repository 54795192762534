import React, { createContext, useContext, useEffect, useState } from 'react';
import { supabase } from '../util/supabaseClient'; // Import Supabase client
import { fetchUserByUid } from '../util/http';

const UserContext = createContext<any>(null);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Supabase auth state change listener
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (session) {
          try {
            const userData = await fetchUserByUid({
              uid: session.user.id,
              signal: null,
            });

            setUser({
              ...session.user,
              ...userData,
              token: session.access_token,
            });
          } catch (error) {
            console.error('Error fetching user data:', error);
          }
        } else {
          setUser(null);
        }
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  // Add token refresh logic
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (user) {
        try {
          const { data, error } = await supabase.auth.getSession();
          if (data?.session) {
            const refreshedToken = data.session.access_token; // Access token inside the session data
            setUser((prevUser: any) => ({
              ...prevUser,
              token: refreshedToken,
            }));
          } else {
            console.error(
              'No session available or error fetching session:',
              error
            );
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      }
    }, 30 * 60 * 1000); // 30 minutes

    return () => clearInterval(intervalId);
  }, [user]);

  const signIn = async (authUser: any) => {
    const { data, error } = await supabase.auth.getSession();
    if (data?.session) {
      const userData = await fetchUserByUid({
        uid: data?.session.user.id,
        signal: null,
      });

      setUser({
        ...authUser,
        ...userData,
        token: data.session.access_token ?? null,
      });
    } else {
      console.error('No session available or error fetching session:', error);
    }
  };

  const signOut = async () => {
    try {
      await supabase.auth.signOut();
      setUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <UserContext.Provider value={{ user, signIn, signOut }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
