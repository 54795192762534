import React from 'react';
import { NavLink } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { useUser } from '../../store/UserContext';
import {
  fetchUserDashboardNotifications,
  markNotificationAsRead,
  queryClient,
  updateNotificationStatus,
} from '../../util/http';

import Section from '../UI/Section';
import LoadingIndicator from '../UI/LoadingIndicator';
import ErrorBlock from '../UI/ErrorBlock';
import Button from '../UI/Button';
import { StarIcon, UsersIcon } from '@heroicons/react/24/outline';
import { useNotifications } from '../../store/NotificationsContext';

export default function Notifications() {
  const { user } = useUser();
  const { fetchAllNotifications } = useNotifications();
  const uid = user?.uid;

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['notifications', { uid, max: 99 }],
    queryFn: ({ signal, queryKey }) => fetchUserDashboardNotifications({ uid, signal, max: 99 }), // ...queryKey[2] = max: 99
  });

  function handleAcceptInvite(invite: any) {
    updateNotificationStatus({
      uid,
      notificationId: invite,
      action: 'ACCEPT',
    })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['notifications', { uid, max: 99 }] });
        queryClient.invalidateQueries({ queryKey: ['collections'] });
        fetchAllNotifications();
      })
      .catch((error) => console.error('Error updating notification status:', error));
  }

  function handleIgnoreInvite(invite: any) {
    updateNotificationStatus({
      uid,
      notificationId: invite,
      action: 'IGNORE',
    })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['notifications', { uid, max: 99 }] });
        fetchAllNotifications();
      })
      .catch((error) => console.error('Error updating notification status:', error));
  }

  function handleAcceptFriend(request: any) {
    updateNotificationStatus({
      uid,
      notificationId: request,
      action: 'ACCEPT',
    })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['notifications', { uid, max: 99 }] });
        fetchAllNotifications();
      })
      .catch((error) => console.error('Error updating notification status:', error));
  }

  function handleIgnoreFriend(request: any) {
    updateNotificationStatus({
      uid,
      notificationId: request,
      action: 'IGNORE',
    })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['notifications', { uid, max: 99 }] });
        fetchAllNotifications();
      })
      .catch((error) => console.error('Error updating notification status:', error));
  }

  function handleMarkAsRead(notificationId: string) {
    markNotificationAsRead({
      uid: user.uid,
      notificationId,
    })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['notifications', { uid, max: 99 }] });
        fetchAllNotifications();
      })
      .catch((error) => console.error('Error marking notification as read:', error));
  }

  let allNotifications: number = 0;
  let content: JSX.Element | null = null;
  let contentCollections: JSX.Element | null = null;
  let contentFriendRequestsAccepted: JSX.Element | null = null;

  if (isPending) {
    content = <LoadingIndicator />;
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch notifications.'} />;
  }

  if (data) {
    let collectionsInvites: any[] = [];
    let friendsRequests: any[] = [];
    let friendRequestsAccepted: any[] = [];

    if (Array.isArray(data)) {
      collectionsInvites = data.filter(
        (notification: any) => notification.type === 'INVITE_TO_COLLECTION' && notification.status === 'PENDING'
      );
      friendsRequests = data.filter(
        (notification: any) => notification.type === 'FRIEND_REQUEST' && notification.status === 'PENDING'
      );
      friendRequestsAccepted = data.filter(
        (notification: any) => notification.type === 'FRIEND_REQUEST_ACCEPTED' && notification.status === 'PENDING'
      );

      allNotifications = collectionsInvites.length + friendsRequests.length + friendRequestsAccepted.length;
      content = <>{collectionsInvites}</>;
    }

    content =
      allNotifications === 0 ? (
        <div className="max-w-3xl m-auto p-12 pb-0 text-gray-700 bg-white shadow flex flex-col gap-12">
          <p className="text-center">There are no new notifications.</p>
        </div>
      ) : (
        <div className="max-w-3xl m-auto flex flex-col gap-12">
          {collectionsInvites.length >= 1 && (
            <div className="p-12 pb-0 text-gray-700 bg-white shadow">
              <h2 className="flex items-center gap-2 text-sm font-bold mb-8">
                <StarIcon className="size-4" />
                {collectionsInvites.length} invite
                {collectionsInvites.length > 1 ? 's' : ''} to join a collection
              </h2>
              {collectionsInvites.map((invite, index) => (
                <div
                  key={invite?.id}
                  className={`my-4 pt-4 sm:pt-0 pb-4 flex flex-col sm:flex-row items-center justify-between gap-4 ${
                    index !== collectionsInvites.length - 1 ? 'border-b border-gray-200' : ''
                  }`}
                >
                  <div className="inline-flex items-center gap-4 w-full">
                    <NavLink
                      to={`/users/${invite.notifier?.username}/`}
                      className="shrink-0 rounded-full outline-none focus:ring-4 transition-all"
                    >
                      <img
                        src={invite.notifier?.image}
                        alt={invite.notifier?.name}
                        className="bg-skeleton rounded-full size-10"
                      />
                    </NavLink>
                    <h3 className="text-pretty">
                      <strong>
                        <NavLink
                          to={`/users/${invite?.notifier?.username}/`}
                          className="hover:underline outline-none focus:ring-4 transition-all ring-offset-4 rounded-sm"
                        >
                          {invite?.notifier?.username}
                        </NavLink>
                      </strong>{' '}
                      invites you to join the collection: "
                      <strong>
                        <NavLink
                          to={`/collections/${invite?.collection?.id}/`}
                          title="See the collection"
                          className="hover:underline outline-none focus:ring-4 transition-all ring-offset-4 rounded-sm tracking-wide"
                        >
                          {invite.collection?.name}
                        </NavLink>
                      </strong>
                      "
                    </h3>
                  </div>
                  <div className="flex gap-4 mt-2 mb-6 sm:my-2">
                    <Button onClick={() => handleIgnoreInvite(invite?.id)} isDestructive>
                      Ignore
                    </Button>
                    <Button isPrimary onClick={() => handleAcceptInvite(invite?.id)}>
                      Accept
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}
          {friendsRequests.length >= 1 && (
            <div className="p-12 pb-0 text-gray-700 bg-white shadow">
              <h2 className="flex items-center gap-2 text-sm font-bold mb-8">
                <UsersIcon className="size-4" />
                {friendsRequests.length} friend request
                {friendsRequests.length > 1 ? 's' : ''}
              </h2>
              {friendsRequests.map((request, index) => (
                <div
                  key={request.id}
                  className={`my-4 pt-4 sm:pt-0 pb-4 flex flex-col sm:flex-row items-center justify-between gap-4 ${
                    index !== friendsRequests.length - 1 ? 'border-b border-gray-200' : ''
                  }`}
                >
                  <div className="inline-flex items-center gap-4 w-full">
                    <NavLink
                      to={`/users/${request.notifier?.username}/`}
                      className="shrink-0 rounded-full outline-none focus:ring-4 transition-all"
                    >
                      <img
                        src={request.notifier?.image}
                        alt={request.notifier?.name}
                        className="bg-skeleton rounded-full size-10"
                      />
                    </NavLink>
                    <h3 className="text-pretty">
                      <strong>
                        <NavLink
                          to={`/users/${request.notifier?.username}/`}
                          className="hover:underline outline-none focus:ring-4 transition-all ring-offset-4 rounded-sm"
                        >
                          {request?.notifier?.username}
                        </NavLink>
                      </strong>{' '}
                      wants to be your friend
                    </h3>
                  </div>
                  <div className="flex gap-4 mt-2 mb-6 sm:my-2">
                    <Button onClick={() => handleIgnoreFriend(request.id)} isDestructive>
                      Ignore
                    </Button>
                    <Button isPrimary onClick={() => handleAcceptFriend(request.id)}>
                      Accept
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}
          {friendRequestsAccepted.length >= 1 && (
            <div className="p-12 pb-0 text-gray-700 bg-white shadow">
              <h2 className="flex items-center gap-2 text-sm font-bold mb-8">
                <UsersIcon className="size-4" />
                {friendRequestsAccepted.length} friend request accepted
                {friendRequestsAccepted.length > 1 ? 's' : ''}
              </h2>
              {friendRequestsAccepted.map((request, index) => (
                <div
                  key={request.id}
                  className={`my-4 pt-4 sm:pt-0 pb-4 flex flex-col sm:flex-row items-center justify-between gap-4 ${
                    index !== friendRequestsAccepted.length - 1 ? 'border-b border-gray-200' : ''
                  }`}
                >
                  <div className="inline-flex items-center gap-4 w-full">
                    <NavLink
                      to={`/users/${request.notifier?.username}/`}
                      className="shrink-0 rounded-full outline-none focus:ring-4 transition-all"
                    >
                      <img
                        src={request.notifier?.image}
                        alt={request.notifier?.name}
                        className="bg-skeleton rounded-full size-10"
                      />
                    </NavLink>
                    <h3 className="text-pretty">
                      <strong>
                        <NavLink
                          to={`/users/${request.notifier?.username}/`}
                          className="hover:underline outline-none focus:ring-4 transition-all ring-offset-4 rounded-sm"
                        >
                          {request?.notifier?.username}
                        </NavLink>
                      </strong>{' '}
                      has accepted your friend request
                    </h3>
                  </div>
                  <div className="flex gap-4 mt-2 mb-6 sm:my-2 shrink-0">
                    <Button onClick={() => handleMarkAsRead(request.id)}>Mark as Read</Button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      );
  }

  return (
    <>
      {content && (
        <Section title={`Notifications (${allNotifications})`} hasIcons={false}>
          {content}
        </Section>
      )}
      {contentCollections && (
        <Section hasIcons={false} wrapperClasses="mt-4">
          {contentCollections}
        </Section>
      )}
      {contentFriendRequestsAccepted && (
        <Section hasIcons={false} wrapperClasses="mt-4">
          {contentFriendRequestsAccepted}
        </Section>
      )}
    </>
  );
}
