import React from 'react';
import RecipeCard from '../Recipes/RecipeCard';
import Button from '../UI/Button';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { Recipe, User } from '../../types';

interface UserRecipesProps {
  user: User;
  isSameUser: boolean;
  recipes: Recipe[];
}

export default function UserRecipesSection({
  user,
  recipes,
  isSameUser,
}: UserRecipesProps) {
  let content: JSX.Element | null = null;

  content = (
    <>
      {recipes.length >= 1
        ? recipes.map((recipe: any) => {
            if (!isSameUser && recipe.draft) return null;
            return <RecipeCard recipe={recipe} key={recipe.id} user={user}/>;
          })
        : !isSameUser ? <p className='mt-4 text-sm text-gray-500'>No recipes added yet.</p> : null}
    </>
  );

  return (
    <div className='flex gap-4 flex-col my-16 md:my-24 lg:my-36'>
      <h2 className='text-2xl text-center  font-bold text-gray-600'>
        {isSameUser
          ? 'My'
          : user.name.split(' ')[0] + "'s" || user.username + "'s"}{' '}
        Recipes
      </h2>
      <div className='flex flex-wrap justify-center text-gray-800'>
        {isSameUser && (
          <Button
            isHuge
            to='/recipes/new/'
            className='flex flex-col justify-center items-center text-center border-dashed border-2 border-gray-200 bg-gray-50 hover:bg-white font-bold shadow-lg rounded-lg m-2 sm:m-4 w-[46%] sm:w-[20rem] min-h-40 sm:min-h-[24rem] hover:shadow-xl transition-all'
          >
            <PlusCircleIcon className='w-8 h-8' />
            <span className='pt-4'>Add a new recipe</span>
          </Button>
        )}
        {content}
      </div>
    </div>
  );
}
