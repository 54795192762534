import React from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import Button from './Button';

export default function AddNewRecipeButton() {
  return <Button
    isHuge
    to='/recipes/new/'
    className='flex flex-col justify-center items-center text-center border-dashed border-2 border-gray-200 bg-gray-50 hover:bg-white font-bold shadow-lg rounded-lg m-2 sm:m-4 w-[46%] sm:w-[20rem] min-h-40 sm:min-h-[24rem] hover:shadow-xl transition-all'
  >
    <PlusCircleIcon className='w-8 h-8' />
    <span className='pt-4'>Add a new recipe</span>
  </Button>;
}
