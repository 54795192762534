import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchUserFriends } from '../../util/http';
import { useUser } from '../../store/UserContext';
import ErrorBlock from '../UI/ErrorBlock';
import LoadingIndicator from '../UI/LoadingIndicator';
import Section from '../UI/Section';
import { User } from '../../types';
import UserCard from "./UserCard";

export default function UserFriends({ user }: { user?: User }) {
  const params = useParams();
  const username = user ? user.username : params.username;

  const { user: loggedUser } = useUser();

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['friends', username, { max: 99 }],
    queryFn: ({ signal }) => fetchUserFriends({ username, signal, max: 99 }),
  });

  let content;
  let totalFriends = 0;

  if (isPending) {
    content = <LoadingIndicator />;
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch friends.'} />;
  }
  if (data) {
    totalFriends = data.length - 1;

    content = (
      <div className="flex flex-wrap gap-12 justify-center">
        {data.length === 0
          ? 'No friends added yet.'
          : data.map((friend: User) => {
              if (friend.username === username) return null;

              return <UserCard key={friend.uid} user={friend} />;
            })}
      </div>
    );
  }

  return (
    <div className="md:max-w-[90%] m-auto">
      <Section
        title={
          loggedUser?.username === username
            ? totalFriends >= 0
              ? 'Your friends (' + totalFriends + ')'
              : 'No friends added yet.'
            : totalFriends >= 0
              ? `${params.username}'s friends (${totalFriends})`
              : `${params.username} has no friends yet.`
        }
      >
        <div className="pt-4">{content}</div>
      </Section>
    </div>
  );
}
