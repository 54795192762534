import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UserForm from './UserForm';
import ErrorBlock from '../UI/ErrorBlock';
import { createNewUser, queryClient } from '../../util/http';
import Button from '../UI/Button';
import { uploadImage } from '../../util/image';
import { User } from '../../types';

export default function NewUser() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [imageFile, setImageFile] = useState<File | null>(null);

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: createNewUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      navigate(`/`);
      navigate(0);
    },
  });

  function handleImageChange(file: File | null) {
    setImageFile(file);
  }

  function handleSubmit(formData: User) {
    if (imageFile) {
      uploadImage(imageFile, formData.username)
        .then((imageUrl) => {
          formData.image = imageUrl;
          mutate({ user: formData });

          navigate(`/users/${formData.username}`);
        })
        .catch((uploadError) => {
          console.error('Error uploading image:', uploadError);
        });
    } else {
      mutate({ user: formData });
      navigate(`/users/${formData.username}`);
    }
  }

  const newUser = {
    uid: searchParams.get('uid'),
    username: searchParams.get('username'),
    name: searchParams.get('name'),
    email: searchParams.get('email') ? decodeURIComponent(searchParams.get('email') ?? '').replace(/ /g, '+') : '',
    image: searchParams.get('image'),
  };

  let title = searchParams.get('title');

  return (
    <section className='max-w-3xl m-auto'>
      <h1 className='text-center text-3xl font-bold p-8 text-gray-700'>
        {title ? title : 'Create Your Profile'}
      </h1>

      <UserForm
        onSubmit={handleSubmit}
        newUser={newUser}
        onImageChange={handleImageChange}
      >
        {isPending && 'Submitting...'}
        {!isPending && (
          <div className='flex gap-4 justify-start'>
            <Button type='submit' isPrimary>
              Create Profile
            </Button>
          </div>
        )}
      </UserForm>

      {isError && (
        <ErrorBlock
          title='Failed to create user'
          message={
            error?.message ||
            'Failed to create user. Please check your inputs and try again later.'
          }
        />
      )}
    </section>
  );
}
