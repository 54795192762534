import React, { useState } from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { deleteRecipe, fetchRecipe, queryClient } from '../../util/http';
import { Helmet } from 'react-helmet';

import Modal from '../UI/Modal';
import ErrorBlock from '../UI/ErrorBlock';
import Section from '../UI/Section';
import Button from '../UI/Button';
import H2 from '../UI/H2';
import LoadingIndicator from '../UI/LoadingIndicator';
import { ArrowTopRightOnSquareIcon, BookmarkIcon, PencilIcon, PhotoIcon, TrashIcon } from '@heroicons/react/24/outline';

import { useUser } from '../../store/UserContext';
import formatDate from '../../util/date';
import Badge from '../UI/Badge';
import { Recipe } from '../../types';

export default function RecipeDetail() {
  const params = useParams<{ recipeId: string }>();
  const navigate = useNavigate();
  const { user } = useUser();

  const [isDeleting, setIsDeleting] = useState(false);

  const { data, isPending, isError, error } = useQuery<Recipe>({
    queryKey: ['recipes', params.recipeId],
    queryFn: ({ signal }) => fetchRecipe({ id: params.recipeId, signal }),
  });

  const {
    mutate,
    isPending: isPendingDeletion,
    isError: isErrorDeleting,
    error: deleteError,
  } = useMutation({
    mutationFn: deleteRecipe,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['recipes'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['users', { uid: user.uid }] });
      queryClient.invalidateQueries({ queryKey: ['collections'] });
      navigate(`/users/${user.username}`);
    },
  });

  function handleStartDelete() {
    setIsDeleting(true);
  }

  function handleStopDelete() {
    setIsDeleting(false);
  }

  function handleDelete() {
    mutate({ id: params.recipeId });
  }

  let content;

  if (isPending) {
    content = (
      <div className="mt-4">
        <LoadingIndicator />
      </div>
    );
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch recipe.'} />;
  }

  if (data) {
    const recipe: Recipe = data;
    const createdAt = formatDate(recipe.created_at);
    const isSameUser = user?.uid === recipe.author?.uid;

    content = (
      <Section
        title={recipe.title}
        wrapperClasses="max-w-4xl lg:max-w-[60rem] m-auto"
        hasFavoriteIcon
        recipe={recipe as any}
      >
        <div className="bg-white shadow-xl rounded-xl m-4">
          {recipe?.image !== null && recipe?.image !== undefined ? (
            <img
              src={recipe.image}
              alt={recipe.title}
              className="bg-skeleton rounded-tl-xl rounded-tr-xl min-h-[28rem] sm:min-h-[36rem] max-h-[60rem] w-full transition"
            />
          ) : (
            <div className="bg-gray-100 opacity-90 object-cover rounded-md w-full h-[32rem] flex flex-col gap-4 justify-center items-center">
              <PhotoIcon className="size-12 text-gray-300" />
              <p className="text-sm text-gray-400">No image added yet</p>
            </div>
          )}
          <div className="flex p-10 md:p-12 gap-12 flex-col md:flex-row relative">
            <div className="m-auto flex items-cente justify-end gap-2 top-10 right-10 md:top-12 md:right-12 absolute">
              {recipe.draft && <Badge type="draft" />}
              {recipe.ai && <Badge type="ai" />}
            </div>
            <div className="w-full md:w-[24rem]">
              <h2 className="text-xl font-bold text-gray-600">Ingredients</h2>
              {recipe.ingredients?.length === 0 && <p className="py-4 text-pretty">No ingredients added yet.</p>}
              <ul className="py-4 text-pretty">
                {recipe.ingredients?.map((ingredient) => {
                  return (
                    <li key={ingredient} className="py-1">
                      {ingredient}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="w-full">
              <h2 className="text-xl font-bold text-gray-600">Steps</h2>
              {recipe.steps?.length === 0 && <p className="py-4 text-pretty">No steps added yet.</p>}
              <ol className="p-4 text-pretty list-decimal marker:text-gray-400">
                {recipe.steps?.map((step) => {
                  return (
                    <li key={step} className="py-4 md:py-2">
                      {step}
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>

          {recipe.source && (
            <div className="px-12 py-4 flex flex-col sm:flex-row gap-12 items-center justify-between text-slate-700 bg-slate-50">
              <h2 className="w-full md:w-[24rem] font-bold text-gray-600">Recipe Source</h2>
              <Link
                className="w-full py-4 text-pretty flex items-center gap-2 hover:underline"
                to={recipe.source}
                title="Open recipe source website"
                target="_blank"
              >
                <span className="text-ellipsis overflow-hidden line-clamp-1">{recipe.source}</span>
                <ArrowTopRightOnSquareIcon className="size-4 shrink-0" />
              </Link>
            </div>
          )}
          <div className="flex justify-center border-t">
            {recipe.author && (
              <h2 className="p-12 flex flex-col sm:flex-row gap-4 items-center justify-centert">
                <NavLink
                  to={`/users/${recipe.author.username}/`}
                  className="flex gap-3 pe-6 items-center rounded-full border border-gray-300 bg-white shadow hover:shadow-lg transition-shadow"
                >
                  <img
                    src={recipe.author?.image}
                    alt={`${recipe.author?.name}'s avatar`}
                    className="size-12 rounded-full border-2 border-white shrink-0"
                  />
                  <span className="font-bold block shrink-0">{recipe.author.name}</span>
                </NavLink>
                <span className="text-center text-balance">
                  added this recipe on <time>{createdAt}</time>
                </span>
                {recipe.category && (
                  <>
                    in
                    <Button to={`/categories/${recipe.category.slug}/`} className="font-bold">
                      {recipe.category.name}
                    </Button>
                  </>
                )}
              </h2>
            )}
            {recipe.collections_count! > 0 && (
              <span
                className="relative my-12 mr-12 px-4 py-2 h-auto flex items-center justify-center gap-1 text-gray-700 bg-gray-50 rounded-lg group cursor-help"
                aria-label={`This recipe has been saved in ${recipe.collections_count} ${
                  recipe.collections_count === 1 ? 'collection' : 'collections'
                }.`}
              >
                <BookmarkIcon className="size-4" />
                {recipe.collections_count}
                <span
                  role="tooltip"
                  className="absolute top-full px-3 py-2 min-w-32 bg-gray-800 text-white text-xs text-center text-balance rounded shadow-lg opacity-0 scale-90 group-hover:opacity-100 group-hover:scale-100 transition-all"
                >
                  {`This recipe has been saved in ${recipe.collections_count} ${
                    recipe.collections_count === 1 ? 'collection' : 'collections'
                  }.`}
                </span>
              </span>
            )}
          </div>
        </div>
        <aside className="py-12 flex justify-center gap-4">
          {isSameUser ? (
            <>
              <NavLink
                to="edit"
                className="py-2 px-4 rounded-lg border border-gray-300 bg-white flex gap-2 items-center shadow hover:shadow-lg transition-shadow"
              >
                <PencilIcon className="w-4 h-4" /> Edit Recipe
              </NavLink>
              <button
                onClick={handleStartDelete}
                className="py-2 px-4 rounded-lg  text-red-100 bg-red-700 flex gap-2 items-center shadow hover:shadow-lg transition-shadow"
              >
                <TrashIcon className="w-4 h-4" /> Delete
              </button>
            </>
          ) : (
            <>
              {/* <button className='py-2 px-4 rounded-lg border  border-gray-400'>
                🔗 &nbsp;Share recipe
              </button>
              <button className='py-2 px-4 rounded-lg border  border-gray-400'>
                🖨️ &nbsp;Print recipe
              </button> */}
            </>
          )}
        </aside>
      </Section>
    );
  }

  return (
    <>
      {data && (
        // @ts-ignore
        <Helmet>
          <title>{`"${data?.title}" by ${data?.user?.name}`}</title>
          <meta property="og:title" content={`"${data?.title}" by ${data?.user?.name}`} />
          <meta property="og:image" content={data?.image} />
          <meta
            property="og:description"
            content={`Recipe added in "${data?.category}" on ${formatDate(data?.created_at)}.`}
          />
        </Helmet>
      )}

      {isDeleting && (
        <Modal>
          <H2 title="Are you sure?" />
          <p>Do you really want to delete this recipe? This action cannot be undone.</p>
          <div className="flex justify-end gap-4 mt-8">
            {isPendingDeletion && <p>Deleting, please wait...</p>}
            {!isPendingDeletion && (
              <>
                <button onClick={handleStopDelete} className="py-2 px-4 rounded-lg border border-gray-400">
                  Cancel
                </button>
                <button
                  onClick={handleDelete}
                  className="py-2 px-4 rounded-lg border text-red-100 bg-red-600 hover:bg-red-700"
                >
                  Delete
                </button>
              </>
            )}
          </div>
          {isErrorDeleting && (
            <ErrorBlock
              title="Failed to delete recipe"
              message={deleteError?.message || 'Failed to delete recipe, please try again later.'}
            />
          )}
        </Modal>
      )}
      {content}
    </>
  );
}
