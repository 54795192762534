import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchUserCollectionsByUsername } from '../../util/http';

import LoadingIndicator from '../UI/LoadingIndicator';
import ErrorBlock from '../UI/ErrorBlock';
import CollectionCard from '../Collections/CollectionCard';
import AddNewCollectionButton from '../UI/AddNewCollectionButton';
import { Collection } from '../../types/collection';

export default function UserCollectionsSection({ isSameUser }: { isSameUser: boolean }) {
  const params = useParams();
  const username = params.username;

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['user-collections', params.username],
    queryFn: ({ signal }) => fetchUserCollectionsByUsername({ signal, username }),
  });

  let content: JSX.Element | null = null;

  if (isPending) {
    content = <LoadingIndicator />;
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch user collections.'} />;
  }

  if (data) {
    const filteredCollections = data.filter((collection: Collection) => !collection.private || isSameUser);

    content =
      filteredCollections.length === 0 ? (
        <div className="max-w-lg m-auto text-center">
          {isSameUser ? (
            <AddNewCollectionButton />
          ) : (
            <p className="text-sm text-gray-500">No collections created yet.</p>
          )}
        </div>
      ) : (
        <div className="flex lg:px-12 gap-8 mx-2 flex-wrap justify-center text-gray-800">
          {isSameUser && <AddNewCollectionButton />}
          {filteredCollections.map((collection: Collection) => (
            <CollectionCard key={collection.id} collection={collection} />
          ))}
        </div>
      );
  }

  return (
    <div className="flex gap-4 flex-col my-16 md:my-24 lg:my-36">
      {isPending && <h2 className="text-2xl text-center  font-bold text-gray-600">Collections</h2>}
      {data && <h2 className="text-2xl text-center  font-bold text-gray-600">{isSameUser && <>My </>} Collections</h2>}
      <div className="mt-4 flex flex-wrap justify-center text-gray-800">{content}</div>
    </div>
  );
}
