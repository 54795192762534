import React from 'react';
import { NavLink } from 'react-router-dom';
import { useUser } from '../../store/UserContext';
import AvatarPlaceholder from '../UI/AvatarPlaceholder';
import AddToCollectionsButton from '../UI/AddToCollectionsButton';
import RemoveFromCollectionsButton from '../UI/RemoveFromCollectionsButton';

import { Recipe, User } from '../../types';
import { PhotoIcon } from '@heroicons/react/24/outline';
import Badge from '../UI/Badge';

export interface RecipeCardProps {
  recipe?: Recipe | undefined | null;
  user?: User | string | null;
  skeleton?: boolean;
  isSmall?: boolean;
  hasRemoveButton?: boolean;
}

export default function RecipeCard({ recipe, user: author, skeleton, isSmall, hasRemoveButton }: RecipeCardProps) {
  const { user } = useUser();

  const recipeCardClasses = `relative flex flex-col shadow-lg rounded-lg bg-white m-2 ${
    isSmall ? 'w-[46%] sm:w-60 sm:h-96' : 'w-[46%] sm:w-[20rem]'
  } hover:shadow-xl transition-shadow`;

  const recipeWrapperClasses = 'h-60 md:h-[24rem] overflow-hidden rounded-t-lg shrink-0';

  const recipeImageClasses = `${isSmall ? 'h-full' : 'h-60 md:h-[24rem]'} w-full`;

  const recipeContentClasses = 'p-4 flex items-center min-h-20 h-[100%] justify-between gap-4';

  const avatarClasses = 'rounded-full size-9 sm:size-10';

  // Skeleton
  if (!recipe) {
    const cardsToRender = 5;

    return (
      <>
        {Array.from({ length: cardsToRender }).map((_, index) => (
          <article key={index} className={recipeCardClasses}>
            <div className={recipeWrapperClasses + ' bg-skeleton bg-gray-300'}></div>

            <div className={recipeContentClasses}>
              <div className="flex gap-4 items-center">
                <div className="flex gap-2 shrink-0">
                  <div className="overflow-hidden rounded-full size-9 sm:size-10">
                    <AvatarPlaceholder noLogo className={avatarClasses} />
                  </div>
                </div>
                <div className="flex flex-col">
                  <h2 className="font-bold text-balance text-sm sm:text-md">Loading...</h2>
                  {!isSmall && <p className="text-sm hidden sm:block">Loading...</p>}
                </div>
              </div>
            </div>
          </article>
        ))}
      </>
    );
  }

  return (
    recipe && (
      <article key={recipe.id} className={`${recipeCardClasses} group`}>
        <NavLink
          to={`/recipes/${recipe.id}`}
          className={recipeWrapperClasses}
          aria-label={`View details for the recipe "${recipe.title}", added by ${recipe.author?.name} in the category ${recipe.category}`}
        >
          {recipe.image !== null && recipe.image !== 'undefined' ? (
            <img
              src={recipe.image}
              alt={recipe.title}
              loading="lazy"
              className={recipeImageClasses + ' bg-skeleton group-hover:opacity-90 transition-opacity'}
            />
          ) : (
            <div className="bg-gray-50 opacity-90 object-cover rounded-md w-full h-full flex flex-col gap-4 justify-center items-center">
              <PhotoIcon className="size-12 text-gray-300" />
              <p className="text-sm text-gray-400">No image added yet</p>
            </div>
          )}
        </NavLink>
        <div className={recipeContentClasses}>
          <div className="flex gap-4 items-center">
            {recipe.author && (
              <NavLink to={`/users/${recipe.author.username}/`} className="flex gap-2 shrink-0">
                {recipe.author?.image ? (
                  <img
                    src={recipe.author?.image}
                    alt={`${recipe.author?.name}'s avatar`}
                    title={recipe.author?.name ? recipe.author?.name : recipe.author?.username}
                    className={avatarClasses + ' bg-skeleton'}
                  />
                ) : (
                  <div className="m-w-[2rem]">
                    <AvatarPlaceholder noLogo />
                  </div>
                )}
              </NavLink>
            )}
            <div className="flex flex-col">
              {recipe.title && (
                <NavLink
                  to={`/recipes/${recipe.id}`}
                  className="font-bold text-balance text-sm sm:text-md text-ellipsis overflow-hidden line-clamp-3 sm:line-clamp-2"
                >
                  <p className="inline">{recipe.title}</p>
                </NavLink>
              )}
              {!isSmall
                ? recipe.author && (
                    <p className="text-sm hidden sm:block">
                      by{' '}
                      <NavLink to={`/users/${recipe.author?.username}/`} className="hover:underline">
                        {recipe.author.username}
                        {/* {recipe.author.name?.split(' ')[0]} */}
                      </NavLink>{' '}
                      {recipe.category && (
                        <>
                          in{' '}
                          <NavLink to={`/categories/${recipe.category.slug}/`} className="hover:underline">
                            {recipe.category.name}
                          </NavLink>
                        </>
                      )}
                    </p>
                  )
                : null}
            </div>
          </div>
          <div className="flex flex-col items-end gap-2">
            {recipe.ai && <Badge type="ai" />}
            {recipe.draft && <Badge type="draft" />}
          </div>
          {user && !isSmall && !hasRemoveButton && (
            <div className="absolute top-2 right-2 sm:top-4 sm:right-4 flex gap-2 bg-white rounded-full sm:opacity-0 sm:group-hover:opacity-100 transition-[opacity, shadown] shadow hover:shadow-lg">
              <AddToCollectionsButton recipeId={recipe.id || ''} recipeTitle={recipe.title} hasOpacity />
            </div>
          )}
          {user && hasRemoveButton && (
            <RemoveFromCollectionsButton recipeId={recipe.id || ''} recipeTitle={recipe.title} />
          )}
        </div>
      </article>
    )
  );
}
