import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useUser } from '../../store/UserContext';
import { deleteCollection, fetchCollection, fetchUserFriends, inviteToCollection, queryClient } from '../../util/http';
import RecipeCard from '../Recipes/RecipeCard';
import EditCollectionButton from './EditCollectionButton';
import Button from '../UI/Button';
import ContentBox from '../UI/ContentBox';
import ErrorBlock from '../UI/ErrorBlock';
import H2 from '../UI/H2';
import LoadingIndicator from '../UI/LoadingIndicator';
import Modal from '../UI/Modal';
import Section from '../UI/Section';
import UsersRoll from '../UI/UsersRoll';
import { CheckCircleIcon, MagnifyingGlassIcon, PlusCircleIcon, UsersIcon } from '@heroicons/react/24/outline';
import { Recipe, User } from '../../types';
import Badge from '../UI/Badge';
import FollowButton from '../UI/FollowButton';

export default function CollectionDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const { user: loggedUser } = useUser();

  const inputClasses =
    'shadow appearance-none border rounded w-full py-3 px-4 h-12 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-4 transition-all';

  const collectionItemClasses = 'w-full p-4 bg-gray-50 rounded-md flex items-center justify-between gap-2 text-left';

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['collections', params.collectionId],
    queryFn: ({ signal }) => fetchCollection({ collectionId: params.collectionId, signal }),
  });

  let content;

  if (isPending) {
    content = <LoadingIndicator />;
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch collection.'} />;
  }
  if (data) {
    let recipes = data.recipes;
    const users = data.users;

    const isSameUser = data.users.some((user: User) => user.uid === loggedUser?.uid);

    if (!isSameUser) {
      recipes = recipes.filter((r: Recipe) => !r.draft);
    }

    content = (
      <div className="mx-2 flex flex-col justify-center items-center gap-12 text-gray-800">
        <ContentBox className="max-w-3xl mx-4 w-full">
          <div className="flex gap-12 justify-between">
            <div className="flex flex-col gap-4 w-full">
              <header className="w-full">
                <div className="flex justify-between items-start">
                  <H2 title={`${data.name}`} />
                  {data.private && <Badge type="private" />}
                </div>
                <p>{data.description}</p>
              </header>
              <div className="mt-4 -mx-8 px-8 pt-6 flex flex-col sm:flex-row items-center gap-8 sm:gap-2 sm:items-end  justify-between text-sm border-t">
                <div className="flex flex-col sm:flex-row gap-4 items-center">
                  <span>Shared by</span>
                  <UsersRoll users={users} />
                </div>
                <div className="flex gap-4">
                  {data.followers_count! > 0 && (
                    <span
                      className="relative m-auto px-4 py-2 h-auto flex items-center justify-center gap-1 text-gray-700 bg-gray-50 rounded-lg group cursor-help"
                      aria-label={`This recipe has been saved in ${data.followers_count} ${
                        data.followers_count === 1 ? 'collection' : 'collections'
                      }.`}
                    >
                      <UsersIcon className="size-4" />
                      {data.followers_count}
                      <span
                        role="tooltip"
                        className="absolute top-full px-3 py-2 min-w-32 bg-gray-800 text-white text-xs text-center text-balance rounded shadow-lg opacity-0 scale-90 group-hover:opacity-100 group-hover:scale-100 transition-all"
                      >
                        {`This collection is being followed by ${data.followers_count} ${
                          data.followers_count === 1 ? 'person' : 'people'
                        }.`}
                      </span>
                    </span>
                  )}
                  {isSameUser ? (
                    <EditCollectionButton
                      collectionId={data.id}
                      setIsDeleting={setIsDeleting}
                      setIsSharing={setIsSharing}
                    />
                  ) : loggedUser ? (
                    <FollowButton
                      uid={loggedUser.uid}
                      entityId={data.id}
                      entityType="collection"
                      initialIsFollowing={loggedUser.follows?.some((follow: any) => follow.entity_id === data.id)}
                    />
                  ) : (
                    <>
                      <Button to="/login">Ask to join</Button>
                      <Button isPrimary to="/login">
                        Follow Collection
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ContentBox>
        <div className="w-full flex flex-wrap justify-center m-auto">
          {isSameUser && (
            <Button
              isHuge
              to={`/recipes/new/?collectionId=${params.collectionId}`}
              className="flex flex-col justify-center items-center text-center border-dashed border-2 border-gray-200 bg-gray-50 hover:bg-white font-bold shadow-lg rounded-lg m-2 sm:m-4 w-[46%] sm:w-[20rem] min-h-40 sm:min-h-[24rem] hover:shadow-xl transition-all"
            >
              <PlusCircleIcon className="w-8 h-8" />
              <p className="pt-4 text-pretty">
                {recipes?.length ? 'Add a new recipe to this collection' : 'Add your first recipe to this collection'}
              </p>
            </Button>
          )}
          {recipes?.length
            ? recipes.map(
                (recipe: Recipe) =>
                  recipe && (
                    <RecipeCard recipe={recipe} user={recipe.author} key={recipe.id} hasRemoveButton={isSameUser} />
                  )
              )
            : !isSameUser && (
                <p className="text-gray-500 text-center w-full mt-4">There are no recipes in this collection yet.</p>
              )}
        </div>
      </div>
    );
  }

  // Delete collection

  const {
    mutate: deleteCollectionMutation,
    isPending: isPendingDelition,
    isError: isErrorDeleting,
    error: errorDeleting,
  } = useMutation({
    mutationFn: deleteCollection,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['collections'] });
      queryClient.invalidateQueries({
        queryKey: ['user-collections', loggedUser.username],
      });
      navigate(`/users/${loggedUser.username}/collections`);
    },
  });

  function handleDelete() {
    deleteCollectionMutation({
      collectionId: params.collectionId,
      username: loggedUser.username,
    });
  }

  function handleStopDelete() {
    setIsDeleting(false);
  }

  // Share collection
  // with others

  const [inviteSent, setInviteSent] = useState(false);

  const {
    mutate: shareCollectionMutation,
    isPending: isPendingSharing,
    isError: isErrorSharing,
    error: errorSharing,
  } = useMutation({
    mutationFn: inviteToCollection,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['collections', params.collectionId],
      });

      setInviteSent(true);
      setSelectedFriends([]);
    },
  });

  function handleShare() {
    shareCollectionMutation({
      collectionId: params.collectionId,
      uid: loggedUser.uid,
      invitedUsersIds: selectedFriends,
    });

    setSearchFriend('');
  }

  const [selectedFriends, setSelectedFriends] = useState<any[]>([]);
  const [searchFriend, setSearchFriend] = useState('');
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();

  // Get friends
  const {
    data: dataUserFriends,
    isPending: isPendingUserFriends,
    isError: isErrorUserFriends,
    error: errorUserFriends,
  } = useQuery({
    queryKey: ['friends', loggedUser?.username, { max: 99 }],
    queryFn: ({ signal }) => fetchUserFriends({ username: loggedUser?.username, signal, max: 99 }),
  });

  const handleSearchFriendChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        setSearchFriend(event.target.value);
      }, 250)
    );
  };

  let filteredUserFriends;

  if (data) {
    const invitedUserIds = data.invitedUserIds?.filter((friend: User) => friend.uid === friend.uid);

    filteredUserFriends = dataUserFriends?.filter((friend: User) => !invitedUserIds?.includes(friend.uid));

    filteredUserFriends = filteredUserFriends?.filter(
      (friend: User) => !data.users?.some((user: User) => user.uid === friend.uid)
    );
  }

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{`${data?.name} | Collections`}</title>
        <meta property="og:title" content={`${data?.name} | Collections`} />
        <meta property="og:image" content={data?.image} />
        <meta property="og:description" content={`${data?.description}`} />
      </Helmet>

      <Section title={`Collections`} wrapperClasses="lg:max-w-[90%] m-auto">
        {content}
      </Section>

      {isSharing && data && (
        <Modal onClose={() => setIsSharing(false)}>
          {inviteSent ? (
            <div className="flex flex-col gap-4 p-8 bg-green-50 rounded-md justify-center -m-8">
              <CheckCircleIcon className="mt-4 w-16 h-16 text-green-700 m-auto" />
              <div className="flex flex-col gap-4 justify-center items-center mb-2">
                <p className="text-center text-balance">
                  You have successfully invited{' '}
                  <strong>
                    {data.users?.length} Friend
                    {selectedFriends?.length > 0 ? '' : 's'}
                  </strong>{' '}
                  to join "<strong>{data.name}</strong>".
                </p>
                <div className="-ms-2 mb-4">
                  <UsersRoll users={data.users as User[]} />
                </div>
                <div className="flex gap-4">
                  <Button
                    onClick={() => {
                      setIsSharing(false);
                      setInviteSent(false);
                    }}
                  >
                    Close
                  </Button>
                  {filteredUserFriends?.length !== 0 && (
                    <Button isPrimary onClick={() => setInviteSent(false)}>
                      Invite more
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <form className="flex flex-col gap-2" onSubmit={(event) => event.preventDefault()}>
              <label htmlFor="search">
                <h2 className="text-lg pb-4 text-center text-pretty">
                  Who would you like to invite to join
                  <br />"<span className="font-bold">{data?.name}</span>"?
                </h2>
              </label>
              <div className="flex justify-between items-center gap-2">
                <input
                  type="search"
                  id="search"
                  name="search"
                  className={inputClasses}
                  placeholder="Invite your friends to join this collection"
                  onChange={handleSearchFriendChange}
                  autoComplete="off"
                />
                <Button
                  isPrimary
                  type="button"
                  // onClick={() => alert('search API for name')}
                  className="h-12 ps-4 pe-4 border-0"
                >
                  <MagnifyingGlassIcon className="size-4 shrink-0" />
                </Button>
              </div>
              <div className="flex flex-col gap-2 w-full">
                <ul className="flex flex-col gap-2" aria-live="assertive">
                  {isPendingUserFriends && (
                    <div className="p-4 flex gap-4 items-center bg-gray-50 rounded-md">
                      <LoadingIndicator />
                      <p className="w-full">Loading friends...</p>
                    </div>
                  )}
                  {filteredUserFriends
                    ?.filter((friend: User) => friend.name.toLowerCase().includes(searchFriend.toLowerCase()))
                    .sort((a: any, b: any) => (a.name ?? '').localeCompare(b.name ?? ''))
                    ?.map((friend: User) => (
                      <li key={friend.username}>
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedFriends((prevState) => {
                              const isSelected = prevState.includes(friend.uid);

                              if (isSelected) {
                                return prevState.filter((uid) => uid !== friend.uid);
                              } else {
                                return [...prevState, friend.uid];
                              }
                            });
                          }}
                          className={
                            selectedFriends.includes(friend.uid)
                              ? `border-gray-700 bg-white transition-all shadow-lg font-bold selected ${collectionItemClasses} focus:outline-none focus:shadow-outline focus:ring-4`
                              : collectionItemClasses
                          }
                        >
                          <div className="text-ellipsis overflow-hidden line-clamp-2">
                            <div className="flex gap-4 items-center">
                              <img
                                src={friend?.image}
                                className="w-8 h-8 rounded-full"
                                // ignore from screen readers
                                alt={`${friend?.name}'s avatar`}
                                aria-hidden="true"
                              />
                              <span>{friend.name}</span>
                            </div>
                          </div>
                          {selectedFriends.includes(friend.uid) ? (
                            <CheckCircleIcon className="w-6 h-6 text-gray-700 shrink-0" />
                          ) : null}
                        </button>
                      </li>
                    ))}
                </ul>
                <div className="flex gap-2 mt-1">
                  <Button onClick={() => setIsSharing(false)}>Cancel</Button>
                  <Button
                    type="button"
                    isPrimary
                    className="w-full"
                    onClick={() => handleShare()}
                    disabled={selectedFriends.length === 0}
                  >
                    {selectedFriends.length > 0 ? (
                      <>
                        {'Share with '}
                        {selectedFriends.length} {selectedFriends.length === 1 ? 'friend' : 'friends'}
                      </>
                    ) : (
                      'Select friends'
                    )}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Modal>
      )}

      {isDeleting && (
        <Modal onClose={() => setIsDeleting(false)}>
          <H2 title="Are you sure?" />
          <p className="text-pretty">Do you really want to delete this collection? This action can not be undone.</p>
          <div className="flex justify-end gap-4 mt-8">
            {isPendingDelition && <p>Deleting, please wait...</p>}
            {!isPendingDelition && (
              <>
                <button
                  onClick={handleStopDelete}
                  className="py-2 px-4 rounded-lg border border-gray-400 outline-none focus:ring-4 transition-all"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDelete}
                  className="py-2 px-4 rounded-lg border text-red-100 bg-red-600 hover:bg-red-700 outline-none focus:ring-4 transition-all"
                >
                  Delete
                </button>
              </>
            )}
          </div>
          {isErrorDeleting && (
            <ErrorBlock
              title="Failed to deactivate user"
              message={errorDeleting?.message || 'Failed to deactivate user, please try again later.'}
            />
          )}
        </Modal>
      )}
    </>
  );
}
