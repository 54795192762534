import React, {useState, useEffect} from 'react';
import {followEntity, queryClient, unfollowEntity} from '../../util/http';
import Button from "./Button";

const FollowButton = ({ uid, entityId, entityType, initialIsFollowing }: {
  uid: string;
  entityId: string;
  entityType: string;
  initialIsFollowing: boolean;
}) => {
  const [isFollowing, setIsFollowing] = useState(initialIsFollowing);

  const handleFollow = async () => {
    await followEntity({ uid, entityId, entityType }).then(() => setIsFollowing(true));
    setIsFollowing(true);

    // Invalidate the query to trigger a re-fetch
    await queryClient.invalidateQueries({ queryKey: [`${entityType}s`, entityId] });

  };

  const handleUnfollow = async () => {
    unfollowEntity({ uid, entityId, entityType }).then(() => setIsFollowing(false));
    setIsFollowing(false);

    // Invalidate the query to trigger a re-fetch
    await queryClient.invalidateQueries({ queryKey: [`${entityType}s`, entityId] });
  };

  return (
    <Button onClick={isFollowing ? handleUnfollow : handleFollow} isPrimary={!isFollowing} isDestructive={isFollowing}>
      {isFollowing ? 'Unfollow' : 'Follow'} {entityType.charAt(0).toUpperCase() + entityType.slice(1)}
    </Button>
  );
};

export default FollowButton;
