import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  BookmarkIcon,
  BookOpenIcon,
  UsersIcon
} from '@heroicons/react/24/outline';
import {User} from "../../types";

export default function UserFriendsButton({
  user
}: {
  user: User;
}) {
  const menuClasses = 'group flex gap-3 w-full items-center px-4 py-3 text-sm';

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <Menu.Button className='inline-flex w-full justify-center rounded-md px-4 py-2 font-medium transition-shadow hover:shadow-lg border border-gray-400 bg-white text-gray-700 hover:text-gray-800 outline-none focus:ring-4'>
        <span>Friends</span>
        <ChevronDownIcon
          className='-mr-1 ml-2 h-5 w-5 text-gray-500'
          aria-hidden='true'
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute z-[1] left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
          <div className='p-[0.125rem]'>
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  to={`/users/${user.username}/friends`}
                  className={`${
                    active ? 'bg-gray-700 text-white' : 'text-gray-800'
                  } ${menuClasses} rounded-t-md`}
                >
                  <UsersIcon className="size-4" />
                  View friends
                </NavLink>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  to={`/users/${user.username}/friends/recipes`}
                  className={`${
                    active ? 'bg-gray-700 text-white' : 'text-gray-800'
                  } ${menuClasses} rounded-md`}
                >
                  <BookOpenIcon className="size-4" />
                  Friends recipes
                </NavLink>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  to={`/users/${user.username}/friends/collections`}
                  className={`${
                    active ? 'bg-gray-700 text-white' : 'text-gray-800'
                  } ${menuClasses} rounded-b-md`}
                >
                  <BookmarkIcon className="size-4" />
                  Friends collections
                </NavLink>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
