import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useUser } from '../../store/UserContext';
import { fetchUserCollectionsByUsername } from '../../util/http';

import Section from '../UI/Section';
import ErrorBlock from '../UI/ErrorBlock';
import LoadingIndicator from '../UI/LoadingIndicator';
import CollectionCard from '../Collections/CollectionCard';
import AddNewCollectionButton from '../UI/AddNewCollectionButton';
import { Collection } from '../../types/collection';

export default function UserCollections() {
  const params = useParams();
  const username = params.username;

  const { user } = useUser();
  const isSameUser = username === user?.username;

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['user-collections', params.username],
    queryFn: ({ signal }) => fetchUserCollectionsByUsername({ signal, username }),
  });

  let content: JSX.Element | null = null;

  if (isPending) {
    content = <LoadingIndicator />;
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch user collections.'} />;
  }

  if (data) {
    const filteredCollections = data.filter((collection: Collection) => !collection.private || isSameUser);

    content =
      filteredCollections.length === 0 ? (
        <div className="max-w-lg m-auto text-center">
          {isSameUser ? <AddNewCollectionButton /> : <p>No collections created yet.</p>}
        </div>
      ) : (
        <div className="flex lg:px-12 gap-8 mx-2 flex-wrap justify-center text-gray-800">
          {isSameUser && <AddNewCollectionButton />}
          {filteredCollections.map((collection: Collection) => (
            <CollectionCard key={collection.id} collection={collection} />
          ))}
        </div>
      );
  }

  return (
    <Section title={`${isSameUser ? 'My' : params.username + "'s"} collections`} wrapperClasses="w-full">
      <div className="pt-4">{content}</div>
    </Section>
  );
}
