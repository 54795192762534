import React from 'react';
import RecipeCard from '../Recipes/RecipeCard';
import { Recipe, User } from '../../types';
import AddNewRecipeButton from '../UI/AddNewRecipeButton';
import { useParams } from 'react-router-dom';
import { useUser } from '../../store/UserContext';
import { useQuery } from '@tanstack/react-query';
import { fetchUserRecipesByUsername } from '../../util/http';
import LoadingIndicator from '../UI/LoadingIndicator';
import ErrorBlock from '../UI/ErrorBlock';
import Section from '../UI/Section';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

export default function UserRecipes() {
  const params = useParams();
  const username = params.username;

  const { user } = useUser();
  const isSameUser = username === user?.username;

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['user-recipes', params.username],
    queryFn: ({ signal }) => fetchUserRecipesByUsername({ signal, username }),
  });

  let content: JSX.Element | null = null;
  if (isPending) {
    content = <LoadingIndicator />;
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch user recipes.'} />;
  }

  const TabBadge = ({ count }: { count: number }) => (
    <span className="bg-slate-200 text-xs px-[0.75rem] py-[0.3rem] tracking-wide rounded-full ml-3">{count}</span>
  );

  // Tabs test
  if (data) {
    const filteredRecipes: Recipe[] = data;
    content = (
      <section className="pt-4 pb-12 mt-8 mx-auto w-full">
        {/* tabs */}
        <nav className="mx-4 sm:mx-0 border-b border-b-gray-200 sticky top-14 lg:top-0 z-10 bg-gray-50 shadow-lg  rounded-lg">
          {/* Desktop */}
          <ul className="hidden sm:flex items-center justify-center gap-8 -mb-[1px]">
            <li>
              <button className="pt-8 pb-6 flex justify-center items-center font-semibold text-gray-800 hover:text-gray-900 border-b-2 border-b-gray-600 transition-all">
                Applied <TabBadge count={52} />
              </button>
            </li>
            <li>
              <button className="pt-8 pb-6 flex justify-center items-center font-semibold text-gray-500 hover:text-gray-700 border-b-2 border-b-transparent hover:border-b-gray-300 transition-all">
                Phone Screening <TabBadge count={6} />
              </button>
            </li>
            <li>
              <button className="pt-8 pb-6 flex justify-center items-center font-semibold text-gray-500 hover:text-gray-700 border-b-2 border-b-transparent hover:border-b-gray-300 transition-all">
                Interview <TabBadge count={4} />
              </button>
            </li>
            <li>
              <button className="pt-8 pb-6 flex justify-center items-center font-semibold text-gray-500 hover:text-gray-700 border-b-2 border-b-transparent hover:border-b-gray-300 transition-all">
                Offer
              </button>
            </li>
            <li>
              <button className="pt-8 pb-6 flex justify-center items-center font-semibold text-gray-500 hover:text-gray-700 border-b-2 border-b-transparent hover:border-b-gray-300 transition-all">
                Disqualified
              </button>
            </li>
          </ul>
          {/* Mobile */}
          <div className="block sm:hidden relative">
            <select
              className="w-full p-4 border border-gray-300 rounded-lg appearance-none text-gray-600"
              aria-label="Select a tab"
            >
              <option>Applied (52)</option>
              <option>Phone Screening</option>
              <option>Interview</option>
              <option>Offer</option>
              <option>Disqualified</option>
            </select>
            <ChevronDownIcon className="size-4 text-gray-500 absolute right-4 top-1/2 transform -translate-y-1/2" />
          </div>
        </nav>
        <div className="mt-8">
          <div className="flex flex-wrap justify-center text-gray-800">
            {/* {filteredRecipes.length >= 1 ? (
              filteredRecipes.map((recipe: Recipe) => {
                if (!isSameUser && recipe.draft) return null;
                return <RecipeCard recipe={recipe} key={recipe.id} user={user} />;
              })
            ) : !isSameUser ? (
              <p className="mt-4 text-sm text-gray-500">No recipes added yet.</p>
            ) : null} */}
            {isSameUser && <AddNewRecipeButton />}
            {filteredRecipes.length >= 1 ? (
              filteredRecipes.map((recipe: Recipe) => {
                // if (!isSameUser && recipe.draft) return null;
                return <RecipeCard recipe={recipe} key={recipe.id} user={user} />;
              })
            ) : !isSameUser ? (
              <p className="mt-4 text-sm text-gray-500">No recipes added yet.</p>
            ) : null}
          </div>
        </div>
        {/* tabs-nav */}
        {/* tabs-panel */}
      </section>
    );
  }

  return (
    <Section title={`${isSameUser ? 'My' : params.username + "'s"} recipes`} wrapperClasses="w-full m-auto">
      {content}
    </Section>
  );
}
