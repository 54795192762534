import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import {
//   applyActionCode,
//   createUserWithEmailAndPassword,
//   sendEmailVerification,
// } from 'firebase/auth';
// import { auth } from '../util/firebase';
import { supabase } from '../util/supabaseClient';

import ContentBox from '../components/UI/ContentBox';
import Section from '../components/UI/Section';
import ErrorBlock from '../components/UI/ErrorBlock';
import Button from '../components/UI/Button';
import {CheckCircleIcon} from "@heroicons/react/24/outline";

export default function SignUp() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const labelClasses = 'block text-gray-800 font-bold mb-4 cursor-pointer';
  const inputClasses =
    'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-4 transition-all';

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: 'https://bokkah.com',
        },
      });

      if (error) throw error;

      setSuccess(true);
    } catch (error: any) {
      setError(error.message || 'An error occurred. Please try again.');
    }
  };

  return (
    <Section
      isNarrow
      title='Create New Account'
      hasIcons={false}
      wrapperClasses='max-w-lg m-auto'
    >
      <ContentBox className=''>
        {success ? (
            <div className='flex gap-4 justify-center items-center'>
              <div className='flex flex-col gap-4 p-4 bg-green-50 -m-6 rounded-md justify-center'>
                <CheckCircleIcon className='mt-4 w-16 h-16 text-green-700 m-auto' />
                <div className='flex flex-col gap-4 justify-center items-center mb-2'>
                  <p className='text-center text-pretty px-4 sm:px-8 lg:px-12'>
                    We’ve just sent a confirmation link to your email address. Please check your inbox and follow the instructions to verify your account.
                  </p>
                  <Button to='/login'>
                    Back to login
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className='flex flex-col gap-x-4'>
              <div className='flex flex-col'>
                <div className='mb-8 w-full'>
                  <label htmlFor='email' className={labelClasses}>
                    E-mail
                  </label>
                  <input
                    id='email'
                    name='email'
                    className={inputClasses}
                    placeholder='Your e-mail address'
                    type='email'
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    autoFocus
                  />
                </div>
                <div className='mb-8 w-full'>
                  <label htmlFor='password' className={labelClasses}>
                    Password
                  </label>
                  <input
                    id='password'
                    name='password'
                    className={inputClasses}
                    type='password'
                    placeholder='Your secret password'
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
            </div>
            <div className='flex gap-4 items-center'>
              <Button isPrimary={true}>Sign Up</Button>
            </div>
        </form>
        )}
      </ContentBox>
      {!success && (
        <p className='m-12 flex flex-col sm:flex-row items-center gap-4 justify-center text-sm text-gray-700 text-center'>
          Have already account?{' '}
          <Button to='/login'>
            <span className="font-bold">Login</span>
          </Button>
        </p>
      )}
      {error && <ErrorBlock title='Failed to create account' message={error} />}
    </Section>
  );
}
