import React, { useState, FormEvent } from 'react';
import { NavLink } from 'react-router-dom';
import {supabase} from "../util/supabaseClient";

import Section from '../components/UI/Section';
import ContentBox from '../components/UI/ContentBox';
import Button from '../components/UI/Button';
import { CheckCircleIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import ErrorBlock from '../components/UI/ErrorBlock';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState<string | null>(null);
  // const searchTerm = encodeURIComponent(email);

  const labelClasses = 'block text-gray-800 font-bold mb-4 cursor-pointer';
  const inputClasses =
    'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-4 outline-none transition-all';

  async function sendPasswordResetEmailSupabase(email: string) {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: 'https://bokkah.com/reset-password',
    });

    if (error) {
      console.error('Error sending password reset email:', error.message);
      throw new Error(`Failed to send password reset email: ${error.message}`);
    }

    console.log('Password reset email sent successfully');
  }

  const handleResetPassword = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    var actionCodeSettings = {
      // After password reset, the user will be give the ability to go back
      // to this page.
      url: 'https://bokkah.com/login',
      handleCodeInApp: false,
    };

    try {
      // // Check if the email exists in the users collection
      // const users = await fetchUsers({ signal: null, searchTerm, max: 1 });
      //
      // if (users.length === 0) {
      //   // User not found
      //   setError('Email address not found.');
      //   return;
      // }

      // Email exists, send the password reset email
      try {
        await sendPasswordResetEmailSupabase(email);
      } catch (error) {
        console.error(error);
      }

      setEmailSent(true);
      setError(null);
    } catch (error) {
      setError('An error occurred. Please try again.');
      console.error(error);
    }
  };

  return (
    <>
      <Section
        isNarrow
        title='Forgot Password'
        hasIcons={false}
        wrapperClasses='max-w-lg m-auto'
        hasRecipes={false}
        hasFavoriteIcon={false}
        // recipe={null}
      >
        <ContentBox>
          <div className='flex flex-col'>
            {emailSent ? (
              <div className='flex gap-4 justify-center items-center'>
                <div className='flex flex-col gap-4 p-4 bg-green-50 -m-6 rounded-md justify-center'>
                  <CheckCircleIcon className='mt-4 w-16 h-16 text-green-700 m-auto' />
                  <div className='flex flex-col gap-4 justify-center items-center mb-2'>
                    <p className='text-center text-pretty px-4 sm:px-8 lg:px-12'>
                      An email with a link to reset your password was
                      successfully sent.
                    </p>
                    <Button to='/login'>
                      Back to login
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <form onSubmit={handleResetPassword}>
                <div className='mb-8 w-full'>
                  <label htmlFor='email' className={labelClasses}>
                    Email
                  </label>
                  <input
                    id='email'
                    name='email'
                    className={inputClasses}
                    type='email'
                    placeholder='Enter your email'
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    autoFocus
                  />
                </div>
                <div className='flex gap-4 items-center'>
                  <Button
                    to='/login'
                    className=''
                    isPrimary={false}
                    isHuge={false}
                    onClick={() => {}}
                    disabled={false}
                    title='Go back to collection list'
                  >
                    <ChevronLeftIcon className='h-6 w-6 text-gray-700' />
                  </Button>
                  <Button
                    isPrimary={true}
                    isHuge={false}
                    onClick={() => handleResetPassword}
                    className=''
                    to=''
                    disabled={false}
                    title=''
                  >
                    Send new password email
                  </Button>
                </div>
              </form>
            )}
          </div>
        </ContentBox>
        <p className='mt-8 w-full flex items-center gap-2 text-sm text-gray-700 justify-center'>
          Don’t have an account yet?
          <NavLink
            to='/sign-up'
            className='hover:underline outline-none focus:ring-4 transition-all ring-offset-4 rounded-sm'
          >
            <strong>Sign up here</strong>
          </NavLink>
        </p>
        {error && <ErrorBlock title='Failed to login' message={error} />}
      </Section>
    </>
  );
}
