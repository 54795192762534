import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchUsers } from '../../util/http';
import ErrorBlock from '../UI/ErrorBlock';
import LoadingIndicator from '../UI/LoadingIndicator';
import Section from '../UI/Section';
import { User } from '../../types';
import UserCard from "./UserCard";

export default function Users() {
  const { data, isPending, isError, error } = useQuery<User[]>({
    queryKey: ['users', { max: 99 }],
    queryFn: ({ signal }) => fetchUsers({ signal, searchTerm: undefined, max: 99 }), // `...queryKey[1]` = `max: 99`
  });

  let content: JSX.Element | null = null;

  if (isPending) {
    content = <LoadingIndicator />;
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch users.'} />;
  }

  if (data) {
    data.sort((a: any, b: any) => (a.name ?? '').localeCompare(b.name ?? ''));

    content = (
      <div className="flex gap-12 flex-wrap justify-center text-gray-800">
        {data.map((user) => (
          <UserCard key={user.uid} user={user} />
        ))}
      </div>
    );
  }

  return (
    <>
      {data && (
        // @ts-ignore
        <Helmet>
          <title>{`All Bokkah users`}</title>
          <meta property="og:title" content={`All Bokkah users`} />
        </Helmet>
      )}
      <Section title="Users" wrapperClasses="lg:max-w-[90%] m-auto">
        <div className="pt-4">{content}</div>
      </Section>
    </>
  );
}
