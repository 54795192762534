import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { activateUser, deactivateUser, fetchUser, queryClient } from '../../util/http';
import { useUser } from '../../store/UserContext';

import Modal from '../UI/Modal';
import Section from '../UI/Section';
import Button from '../UI/Button';
import H2 from '../UI/H2';
import LoadingIndicator from '../UI/LoadingIndicator';
import { PencilIcon } from '@heroicons/react/24/outline';
import UserRecipesSection from './UserRecipesSection';
import UserCollectionsSection from './UserCollectionsSection';
import UserFriendsButton from './UserFriendsButton';
import AddToFriendsButton from '../UI/AddToFriendsButton';

export default function UserDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useUser();
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [isActivating, setIsActivating] = useState(false);

  const { data, isPending } = useQuery({
    queryKey: ['users', params.username],
    queryFn: ({ signal }) => fetchUser({ username: params.username, signal }),
  });

  const {
    mutate: deactivateUserMutation,
    isPending: isPendingDeactivation,
    isError: isErrorDeactivating,
    error: deactivationError,
  } = useMutation({
    mutationFn: deactivateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users'],
        refetchType: 'none',
      });
      navigate('/users');
    },
  });

  const {
    mutate: activateUserMutation,
    isPending: isPendingActivation,
    isError: isErrorActivating,
    error: activationError,
  } = useMutation({
    mutationFn: activateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users'],
        refetchType: 'none',
      });
      navigate('/users');
    },
  });

  function handleStartDeactivation() {
    // console.log('start deactivation');
    setIsDeactivating(true);
  }

  function handleStopDeactivation() {
    // console.log('stop deactivation');
    setIsDeactivating(false);
  }

  function handleDeactivate() {
    deactivateUserMutation({ username: params.username });
  }

  function handleStartActivation() {
    // console.log('start Activation');
    setIsActivating(true);
  }

  function handleStopActivation() {
    // console.log('stop Activation');
    setIsActivating(false);
  }

  function handleActivate() {
    activateUserMutation({ username: params.username });
  }

  let content;

  if (isPending) {
    content = (
      <div className="mt-4">
        <LoadingIndicator />
      </div>
    );
  }

  if (data) {
    const isSameUser = user?.uid === data?.uid && user !== null;

    content = (
      <Section>
        <div className="bg-white shadow-xl rounded-xl m-4 sm:m-auto max-w-2xl">
          <div className="flex justify-center mt-28">
            <img
              src={data.image ?? '/logo512.png'}
              alt={`Portrait of ${data.name}`}
              className="rounded-full size-[12rem] relative -mt-28 bg-skeleton border-4 border-white shadow-lg"
            />
          </div>
          <div className="flex px-12 sm:px-16 pb-12 gap-12 flex-col sm:flex-row text-pretty">
            <div className="w-full text-center text-pretty">
              <h1 className="text-center text-2xl font-bold p-8 text-gray-700">{data.name ?? data.username}</h1>
              {data.bio && <p>{data.bio}</p>}
            </div>
          </div>
          <div className="px-12 pb-12 flex flex-col sm:flex-row gap-4 justify-center w-full text-center md:text-left">
            {user && user?.id !== data.uid ? (
              <AddToFriendsButton user={user} friendData={data} />
            ) : (
              user && (
                <Button
                  to={`/users/${data.username}/edit/`}
                  isPrimary
                  className="flex gap-3 items-center justify-center"
                >
                  <PencilIcon className="w-4 h-4" /> Edit Your Profile
                </Button>
              )
            )}
            <UserFriendsButton user={data} />
          </div>
        </div>
        {data.recipes !== null && <UserRecipesSection user={data} isSameUser={isSameUser} recipes={data.recipes} />}
        <UserCollectionsSection isSameUser={isSameUser} />
      </Section>
    );
  }

  return (
    <>
      {data && (
        // @ts-ignore
        <Helmet>
          <title>{`${data?.name}'s profile`}</title>
          <meta property="og:title" content={`${data?.name}'s profile`} />
          <meta property="og:image" content={data?.image} />
          <meta property="og:description" content={`${data?.bio}`} />
        </Helmet>
      )}

      {isDeactivating && (
        <Modal>
          <H2 title="Are you sure?" />
          <p>Do you really want to deactivate this user?</p>
          <div className="flex">
            {isPendingDeactivation && <p>Deactivating, please wait...</p>}
            {!isPendingDeactivation && (
              <>
                <button onClick={handleStopDeactivation} className="py-2 px-4 rounded-lg border border-gray-400">
                  Cancel
                </button>
                <button
                  onClick={handleDeactivate}
                  className="py-2 px-4 rounded-lg border text-red-100 bg-red-600 hover:bg-red-700"
                >
                  Deactivate
                </button>
              </>
            )}
          </div>
          {/* {isErrorDeactivating && (
            <ErrorBlock
              title='Failed to deactivate user'
              message={
                deactivationerror?.message ||
                'Failed to deactivate user, please try again later.'
              }
            />
          )} */}
        </Modal>
      )}

      {isActivating && (
        <Modal>
          <H2 title="Are you sure?" />
          <p>Do you really want to activate this user?</p>
          <div className="flex">
            {isPendingDeactivation && <p>Activating, please wait...</p>}
            {!isPendingDeactivation && (
              <>
                <button onClick={handleStopActivation} className="py-2 px-4 rounded-lg border border-gray-400">
                  Cancel
                </button>
                <button onClick={handleActivate} className="py-2 px-4 rounded-lg border text-gray-50 bg-gray-700">
                  Activate
                </button>
              </>
            )}
          </div>
          {/* {isErrorActivating && (
            <ErrorBlock
              title='Failed to activate user'
              message={
                activationerror?.message ||
                'Failed to deactivate user, please try again later.'
              }
            />
          )} */}
        </Modal>
      )}

      {content}
    </>
  );
}
