import React, { useEffect, useState } from 'react';
import Button from './Button';
import { useQuery } from '@tanstack/react-query';
import {
  addToFriends,
  fetchNotifications,
  queryClient,
  removeFromFriends,
  updateNotificationStatus,
} from '../../util/http';
import { User } from '../../types';
import { useNotifications } from '../../store/NotificationsContext';
import Modal from './Modal';
import H2 from './H2';
import ErrorBlock from './ErrorBlock';

export default function AddToFriendsButton({ user, friendData }: { user: User; friendData: any }) {
  const { notifications, fetchAllNotifications } = useNotifications();
  const [buttonState, setButtonState] = useState<'add' | 'sent' | 'accept' | 'following'>('add');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [friendRequestNotificationId, setFriendRequestNotificationId] = useState<string | null>(null);
  const [isRemoving, setIsRemoving] = useState(false);

  // Fetch `notifications` from the service
  const { data: notificationsService, fetchStatus } = useQuery({
    queryKey: ['notifications', user.uid],
    queryFn: ({ signal }) => fetchNotifications({ uid: friendData.uid, signal, max: null }),
    enabled: !!user.uid, // The query will not execute until the user.uid exists
    staleTime: 1 * 10 * 1000, // 10 secs
  });

  useEffect(() => {
    if (!notifications || !user || !friendData) return;

    const pendingRequest = notifications.some((notification: any) => {
      return (
        notification.type === 'FRIEND_REQUEST' &&
        notification.status === 'PENDING' &&
        notification.from_user === friendData?.uid &&
        notification.notifier === user?.uid
      );
    });

    const sentRequest = notificationsService?.some((notification: any) => {
      return (
        notification.type === 'FRIEND_REQUEST' &&
        notification.status === 'PENDING' &&
        notification.from_user === user?.uid &&
        notification.notifier === friendData?.uid
      );
    });

    if (pendingRequest) {
      const notification = notifications.find(
        (n: any) =>
          n.type === 'FRIEND_REQUEST' &&
          n.status === 'PENDING' &&
          n.from_user === friendData?.uid &&
          n.notifier === user?.uid
      );

      if (notification) setFriendRequestNotificationId(notification.id);

      setButtonState('accept');
    } else if (sentRequest) {
      setButtonState('sent');
    } else if (user.friends?.some((friend: User) => friend.uid === friendData?.uid)) {
      setButtonState('following');
    }
  }, [notifications, notificationsService, user, friendData]);

  function handleAcceptFriend(notificationId: string) {
    setIsButtonDisabled(true);

    updateNotificationStatus({
      uid: user.uid,
      notificationId,
      action: 'ACCEPT',
    })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['notifications', user.uid] });
        queryClient.invalidateQueries({ queryKey: ['users', user.username] });
        fetchAllNotifications();
        setIsButtonDisabled(false);
        setButtonState('following'); // Update local state
      })
      .catch((error) => console.error('Error updating notification status:', error));
  }

  function handleAddToFriends(uid: string) {
    setIsButtonDisabled(true);

    addToFriends({
      uid: user?.uid,
      notifier: uid,
    })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['notifications', user.uid] });
        queryClient.invalidateQueries({ queryKey: ['users', user.username] });
        fetchAllNotifications();
        setIsButtonDisabled(false);
        setButtonState('sent'); // Update local state
      })
      .catch((error) => console.error('Error sending friend request:', error));
  }

  function handleRemoveFromFriends(uid: string) {
    setIsButtonDisabled(true);

    removeFromFriends({
      uid: user?.uid,
      notifier: uid,
    })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['users', user.username] });
        setIsRemoving(false);
        setIsButtonDisabled(false);
        setButtonState('add');
      })
      .catch((error) => console.error('Error removing friend request:', error));
  }

  function handleStartRemove() {
    setIsRemoving(true);
  }

  function handleStopRemove() {
    setIsRemoving(false);
  }

  let button;

  if (buttonState === 'add') {
    button = (
      <Button
        isPrimary
        onClick={() => handleAddToFriends(friendData.uid)}
        disabled={fetchStatus === 'fetching' || isButtonDisabled}
      >
        Add to Friends
      </Button>
    );
  }

  if (buttonState === 'accept') {
    button = (
      <Button
        isPrimary
        onClick={() => handleAcceptFriend(friendRequestNotificationId!)}
        disabled={fetchStatus === 'fetching' || isButtonDisabled}
      >
        Accept Friend Request
      </Button>
    );
  }

  if (buttonState === 'sent') {
    button = (
      <Button isPrimary disabled>
        Friend Request Sent
      </Button>
    );
  }

  if (buttonState === 'following') {
    button = (
      <Button
        isDestructive
        onClick={() => handleStartRemove()}
        disabled={fetchStatus === 'fetching' || isButtonDisabled}
      >
        Stop Following
      </Button>
    );
  }

  return (
    <>
      {isRemoving && (
        <Modal>
          <H2 title="Are you sure?" />
          <p>
            Do you really want to remove <strong className="font-bold">{friendData.username}</strong> from your friends?
          </p>
          <div className="flex justify-end gap-4 mt-8">
            <Button
              onClick={handleStopRemove}
              className="py-2 px-4 rounded-lg border border-gray-400 outline-none focus:ring-4 transition-all"
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleRemoveFromFriends(friendData.uid)}
              className="py-2 px-4 rounded-lg border text-red-100 bg-red-600 hover:bg-red-700 outline-none focus:ring-4 transition-all"
            >
              Remove
            </Button>
          </div>
        </Modal>
      )}
      {button}
    </>
  );
}
