import React from "react";
import { NavLink } from "react-router-dom";
import { User } from '../../types';

export default function UserCard({ user}: {user: User}) {
    return user && (<div key={user.uid} className="p-4 px-8 bg-white rounded shadow-md mt-20">
        <NavLink to={`/users/${user.username}/`} className="flex flex-col items-center gap-4">
            <img
                src={user.image ?? '/logo192.png'}
                alt={`${user.name ?? user.username}'s avatar`}
                className="bg-skeleton rounded-full size-36 relative -top-20 -mb-20 border-white border-4 bg-gray-200"
            />
            <h2 className="font-bold my-2">{user.name ?? user.username}</h2>
            {/*<h3 className='text-xs tracking-wide text-gray-600'>{user.country}</h3>*/}
        </NavLink>
    </div>)
}