export default function formatDate(date) {
  if (!date) return '';

  const parsedDate = new Date(date);

  // Use Intl.DateTimeFormat for a friendly format
  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return formatter.format(parsedDate);
}
