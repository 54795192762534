import React, { useState } from 'react';
import SelectCollectionModal from '../Collections/SelectCollectionModal';
import { BookmarkIcon } from '@heroicons/react/24/outline';

export default function AddToCollectionsButton({
  className,
  recipeId,
  recipeTitle,
  hasOpacity = false,
}: {
  className?: string;
  recipeId: string;
  recipeTitle: string;
  hasOpacity?: boolean;
}) {
  const [showCollections, setShowCollections] = useState(false);

  return (
    <>
      <button
        onClick={() => setShowCollections(true)}
        className={`p-1 sm:p-2 sm:px-3 gap-1 flex items-center justify-center ${
          hasOpacity && 'opacity-60 hover:opacity-100'
        } ${className}`}
        title='Save to collection'
      >
        {hasOpacity && <span className='font-bold hidden sm:block text-sm'>Add to Collection</span>}
        <BookmarkIcon className='size-5' />
      </button>
      {showCollections && (
        <SelectCollectionModal
          recipeId={recipeId}
          recipeTitle={recipeTitle}
          onClose={() => setShowCollections(false)}
        />
      )}
    </>
  );
}
