import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { supabase } from '../util/supabaseClient'; // Import the Supabase client
import { useUser } from '../store/UserContext';
import Section from '../components/UI/Section';
import ContentBox from '../components/UI/ContentBox';
import ErrorBlock from '../components/UI/ErrorBlock';
import Button from '../components/UI/Button';
import { fetchUserByUid } from '../util/http';

export default function Login() {
  const navigate = useNavigate();
  const { signIn: setUser } = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  const labelClasses = 'block text-gray-800 font-bold mb-4 cursor-pointer';
  const inputClasses =
    'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-4 transition-all';

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null);

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;

      const userData = await fetchUserByUid({
        uid: data.user.id,
        signal: null,
      });

      if (userData === null) {
        navigate(`/users/new/?email=${email}&uid=${data.user?.id}`);
      }

      setUser(data.user);

      navigate(`/users/${userData.username}/`);
    } catch (error: any) {
      setError(error.message || 'An error occurred. Please try again.');
    }
  };

  const signInWithGoogle = async () => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
      });

      if (error) throw error;
    } catch (error: any) {
      setError(error.message || 'Google sign-in error!');
      console.error('Google sign-in error:', error);
    }
  };

  return (
    <Section
      isNarrow
      title='Welcome back'
      hasIcons={false}
      wrapperClasses='max-w-lg m-auto'
    >
      <ContentBox className=''>
        <div className='flex flex-col'>
          <Button
            onClick={signInWithGoogle}
            className='relative flex items-center justify-center gap-4 py-4 m-auto mb-4 w-full'
          >
            <svg
              viewBox='0 0 21 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='w-6 h-6'
            >
              <g clipPath='url(#clip0_13183_10121)'>
                <path
                  d='M20.3081 10.2303C20.3081 9.55056 20.253 8.86711 20.1354 8.19836H10.7031V12.0492H16.1046C15.8804 13.2911 15.1602 14.3898 14.1057 15.0879V17.5866H17.3282C19.2205 15.8449 20.3081 13.2728 20.3081 10.2303Z'
                  fill='#3F83F8'
                ></path>
                <path
                  d='M10.7019 20.0006C13.3989 20.0006 15.6734 19.1151 17.3306 17.5865L14.1081 15.0879C13.2115 15.6979 12.0541 16.0433 10.7056 16.0433C8.09669 16.0433 5.88468 14.2832 5.091 11.9169H1.76562V14.4927C3.46322 17.8695 6.92087 20.0006 10.7019 20.0006V20.0006Z'
                  fill='#34A853'
                ></path>
                <path
                  d='M5.08857 11.9169C4.66969 10.6749 4.66969 9.33008 5.08857 8.08811V5.51233H1.76688C0.348541 8.33798 0.348541 11.667 1.76688 14.4927L5.08857 11.9169V11.9169Z'
                  fill='#FBBC04'
                ></path>
                <path
                  d='M10.7019 3.95805C12.1276 3.936 13.5055 4.47247 14.538 5.45722L17.393 2.60218C15.5852 0.904587 13.1858 -0.0287217 10.7019 0.000673888C6.92087 0.000673888 3.46322 2.13185 1.76562 5.51234L5.08732 8.08813C5.87733 5.71811 8.09302 3.95805 10.7019 3.95805V3.95805Z'
                  fill='#EA4335'
                ></path>
              </g>
              <defs>
                <clipPath id='clip0_13183_10121'>
                  <rect
                    width='20'
                    height='20'
                    fill='white'
                    transform='translate(0.5)'
                  ></rect>
                </clipPath>
              </defs>
            </svg>
            <span>Log in with Google</span>
          </Button>
          <div className='mb-8 w-full text-center border-b'>
            <span className='relative top-3 p-4 bg-white'>or</span>
          </div>
          <form onSubmit={handleLogin}>
            <div className='mb-8 w-full'>
              <label htmlFor='email' className={labelClasses}>
                Email
              </label>
              <input
                id='email'
                name='email'
                className={inputClasses}
                placeholder='Enter your email'
                type='email'
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className='mb-8 w-full'>
              <div className='flex justify-between items-center'>
                <label htmlFor='password' className={labelClasses}>
                  Password
                </label>
                <NavLink
                  to='/forgot-password'
                  title='Click here to reset your password'
                  className='text-xs text-gray-700 font-bold hover:underline'
                  tabIndex={-1}
                >
                  Forgot password?
                </NavLink>
              </div>
              <input
                id='password'
                name='password'
                className={inputClasses}
                type='password'
                placeholder='************'
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <div className='flex gap-4 items-center'>
              <Button isPrimary onClick={() => handleLogin}>
                Sign in to your account
              </Button>
            </div>
          </form>
        </div>
      </ContentBox>
      <p className='mt-8 w-full flex items-center gap-2 text-sm text-gray-700 justify-center'>
        Don’t have an account yet?
        <NavLink
          to='/sign-up'
          className='hover:underline outline-none focus:ring-4 transition-all ring-offset-4 rounded-sm'
        >
          <strong>Sign up here</strong>
        </NavLink>
      </p>
      {error && <ErrorBlock title='Failed to login' message={error} />}
    </Section>
  );
}
