import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { createNewRecipe, generateRecipeFromIngredients, queryClient } from '../../util/http';
import { useUser } from '../../store/UserContext';
import Section from '../UI/Section';
import Button from '../UI/Button';
import { set, useForm } from 'react-hook-form';
import LoadingIndicator from '../UI/LoadingIndicator';
import { BookOpenIcon, PencilIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { Recipe } from '../../types';
import Badge from '../UI/Badge';
import ErrorBlock from '../UI/ErrorBlock';

const labelClasses = 'block text-gray-800 font-bold mb-4 cursor-pointer';
const inputClasses =
  'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading focus:outline-none focus:shadow-outline focus:ring-4 transition-all';

export default function AIGenerateRecipes() {
  const navigate = useNavigate();
  const { user } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [aiResponse, setAiResponse] = useState<Recipe[] | null>(null);
  const [savingState, setSavingState] = useState<Record<number, { loading: boolean; recipeId: string | null }>>({});
  const [isErrorAIRequest, setIsErrorAIRequest] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { register, getValues } = useForm({
    defaultValues: {
      ingredients: '',
      kitchen_type: '',
      inspired_by: '',
      servings: null,
    },
  });

  const {
    mutate: mutateAIRequest,
    isPending: isPendingAIRequest,
    error: errorAIRequest,
  } = useMutation({
    mutationFn: generateRecipeFromIngredients,
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['recipes'] });
      setAiResponse(response);
    },
    onError: (error: any) => {
      setIsErrorAIRequest(true);
      setErrorMessage(error?.message || 'Failed to generate recipes.');
    },
    onSettled: () => {
      setIsSubmitting(false); // Always reset submitting state
    },
  });

  const {
    mutate: mutateNewRecipe,
    isPending: isPrendingRecipe,
    isError: isErrorRecipe,
    error: erroRecipe,
  } = useMutation({
    mutationFn: createNewRecipe,
    onSuccess: (response, { index }) => {
      queryClient.invalidateQueries({ queryKey: ['recipes'] });
      console.log(response, ' ========= response == saving recipe');
      console.log(index, ' ========= index == saving recipe');

      setSavingState((prevState) => ({
        ...prevState,
        [index]: { loading: false, recipeId: response.id },
      }));
    },
  });

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsSubmitting(true);
    setAiResponse(null);
    setIsErrorAIRequest(false);

    const ingredients = getValues('ingredients');
    const kitchen_type = getValues('kitchen_type');
    const inspired_by = getValues('inspired_by');

    mutateAIRequest({ ingredients, kitchen_type, inspired_by });
  }

  function handleCreateRecipe(recipe: Recipe, index: number) {
    console.log(recipe, '======== recipe data');
    console.log(index, '======== index');

    setSavingState((prevState) => ({
      ...prevState,
      [index]: { loading: true, recipeId: null },
    }));

    let newRecipe;

    newRecipe = {
      title: recipe.title,
      // category: recipe.category,
      category: 3,
      ingredients: recipe.ingredients,
      steps: recipe.steps,
      author: user.uid,
      ai: true,
      draft: true,
    };

    mutateNewRecipe({ recipe: newRecipe, index });
  }

  return (
    <>
      {/* AI Error */}
      {isErrorAIRequest && (
        <ErrorBlock
          title="An error occurred"
          message={errorMessage}
          onClose={() => setIsErrorAIRequest(false)} // Reset error state after fade-out
        />
      )}

      {/* AI Generated Recipes */}
      {aiResponse && (
        <Section title="AI-generated Recipes" hasIcons={false} wrapperClasses="w-full mb-12 sm:mb-16 md:mb-20 lg:mb-24">
          <div className="flex justify-center flex-wrap w-full">
            {/*@ts-ignore*/}
            {aiResponse?.map((recipe: Recipe, index: number) => {
              return (
                <article
                  key={index}
                  className="flex flex-col justify-between bg-white shadow-xl rounded-xl m-4 max-w-[34rem]"
                >
                  <div className="py-6 px-12 flex flex-row justify-between items-center gap-4">
                    <h2 className="font-bold text-gray-600 text-pretty">{recipe.title}</h2>
                    <Badge type="ai" />
                  </div>
                  {recipe.image !== null && recipe.image !== undefined ? (
                    <img
                      src={recipe.image}
                      alt={recipe.title}
                      className="bg-skeleton rounded-tl-xl rounded-tr-xl h-[20rem] w-full transition"
                    />
                  ) : (
                    <div className="bg-gray-600 opacity-90 object-cover rounded-md w-full h-[20rem] flex flex-col gap-4 justify-center items-center">
                      <SparklesIcon className="size-12 text-gray-300" />
                      <p className="text-sm text-gray-200">Add your image later</p>
                    </div>
                  )}
                  <div className="flex justify-between p-10 md:p-12 md:pb-8 gap-8 flex-col relative text-gray-700">
                    <div className="w-full">
                      <h3 className="font-bold text-gray-600">Ingredients</h3>
                      <ul className="py-4 text-pretty inline-block">
                        {recipe.ingredients?.map((ingredient, index) => {
                          const isLast = index === recipe.ingredients?.length - 1;
                          return (
                            <li key={ingredient} className="py-1 inline-block mr-2 tracking-wide text-sm">
                              {ingredient}
                              {!isLast && <span className="text-gray-300 text-xs tracking-widest">&nbsp; | </span>}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="w-full">
                      <h3 className="font-bold text-gray-600">Steps</h3>
                      <ol className="p-4 text-pretty list-decimal marker:text-gray-400">
                        {recipe.steps?.map((step) => (
                          <li key={step} className="py-4 md:py-2">
                            {step}
                          </li>
                        ))}
                      </ol>
                    </div>
                  </div>

                  <div className="flex justify-center border-t mt-auto p-5 items-center gap-4 min-h-24 sm:min-h-20">
                    {savingState[index]?.loading ? (
                      <LoadingIndicator />
                    ) : savingState[index]?.recipeId ? (
                      <div className="flex justify-center w-full gap-4">
                        <Button
                          to={`/recipes/${savingState[index].recipeId}/edit`}
                          className="flex justify-center items-center gap-2 w-full"
                        >
                          <PencilIcon className="size-4" /> Edit Recipe
                        </Button>
                      </div>
                    ) : (
                      <Button
                        isPrimary
                        onClick={() => handleCreateRecipe(recipe, index)}
                        className="flex justify-center items-center gap-2 w-full"
                      >
                        <BookOpenIcon className="size-4" /> Save to My Recipes
                      </Button>
                    )}
                  </div>
                </article>
              );
            })}
          </div>
        </Section>
      )}

      {/* AI Form */}
      <Section isNarrow title="Generate Recipes with AI" hasIcons={false}>
        <div className="p-8 bg-white shadow-xl rounded-xl">
          {isPendingAIRequest && (
            <div className="flex flex-col p-12 gap-12 justify-center items-center">
              <LoadingIndicator />
              <div className="flex gap-2 items-center">
                <SparklesIcon className="size-6" />
                <p className="text-sm">Generating recipes with AI...</p>
              </div>
            </div>
          )}
          {!isPendingAIRequest && (
            <form onSubmit={handleSubmit}>
              <div className="mb-8">
                <label htmlFor="ingredients" className={labelClasses}>
                  Ingredients List
                </label>
                <textarea
                  id="ingredients"
                  {...register('ingredients')}
                  placeholder="Enter all the ingredients and spices you have on hand. These will be the base for your personalized, AI-generated recipes."
                  className={inputClasses + ' leading-7'}
                  rows={4}
                  maxLength={500}
                  required
                  autoFocus={!aiResponse}
                />
              </div>
              <div className="mb-8">
                <label htmlFor="kitchen_type" className={labelClasses}>
                  Kitchen Type
                </label>
                <input
                  id="kitchen_type"
                  {...register('kitchen_type')}
                  placeholder="e.g., Mediterranean, Asian, Italian, African..."
                  className={inputClasses}
                  maxLength={120}
                />
              </div>
              <div className="mb-8 flex justify-stretch gap-8">
                <div className="w-full">
                  <label htmlFor="inspired_by" className={labelClasses}>
                    Inspired By
                  </label>
                  <input
                    id="inspired_by"
                    {...register('inspired_by')}
                    placeholder="e.g., Jamie Oliver, Jeroen Meus, Your Grandma..."
                    className={inputClasses}
                    maxLength={120}
                  />
                </div>
                <div className="mb-8 min-w-20 sm:min-w-32">
                  <label htmlFor="servings" className={labelClasses}>
                    Servings
                  </label>
                  <input
                    id="servings"
                    {...register('servings')}
                    placeholder="Enter the number of servings you'd like"
                    className={inputClasses}
                    type="number"
                    min={1}
                    max={99999}
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <Button type="submit" isPrimary disabled={isSubmitting}>
                  <div className="flex gap-2 items-center ">
                    <SparklesIcon className="size-4" /> Generate Recipes
                  </div>
                </Button>
              </div>
            </form>
          )}
        </div>
      </Section>
    </>
  );
}
