import { createClient } from '@supabase/supabase-js';

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL || 'https://tokolmgnwpqvmsdurfhi.supabase.co';
const SUPABASE_ANON_KEY = process.env.REACT_APP_SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRva29sbWdud3Bxdm1zZHVyZmhpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzEzNTc3MzMsImV4cCI6MjA0NjkzMzczM30.6dPedz4oZnS05O6QXjweEzzHGy0aiBMixRYqATnruTs';

if (!SUPABASE_URL || !SUPABASE_ANON_KEY) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);
