import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchUserFriendsRecipes } from '../../util/http';
import { useUser } from '../../store/UserContext';
import ErrorBlock from '../UI/ErrorBlock';
import LoadingIndicator from '../UI/LoadingIndicator';
import RecipeCard from '../Recipes/RecipeCard';
import Section from '../UI/Section';
import { Recipe, User } from '../../types';

export default function UserFriendsRecipes({ user }: { user?: User }) {
  const params = useParams();
  const username = user ? user.username : params.username;
  const { user: loggedUser } = useUser();

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['friends-recipes', username, { max: 99 }],
    queryFn: ({ signal }) => fetchUserFriendsRecipes({ username, signal, max: 99 }),
  });

  let content;

  if (isPending) {
    content = <LoadingIndicator />;
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch friends recipes.'} />;
  }
  if (data) {
    content = (
      <div className="flex flex-wrap justify-center">
        {data.length === 0
          ? 'No recipes or friends added yet.'
          : data.map((recipe: Recipe) => {
              return <RecipeCard recipe={recipe} user={recipe.author} key={recipe.id} />;
            })}
      </div>
    );
  }

  return (
    <div className="md:max-w-[90%] m-auto">
      <Section
        title={
          loggedUser?.username === username
            ? 'Your friends recipes'
            : `${params.username} friends' recipes (${data?.length})`
        }
      >
        <div className="pt-4">{content}</div>
      </Section>
    </div>
  );
}
