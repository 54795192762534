import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchCollections } from '../../util/http';

import Section from '../UI/Section';
import LoadingIndicator from '../UI/LoadingIndicator';
import ErrorBlock from '../UI/ErrorBlock';
import { Helmet } from 'react-helmet';
import CollectionCard from './CollectionCard';
import { Collection } from '../../types/collection';

export default function Collections() {
  const { data, isPending, isError, error } = useQuery({
    queryKey: ['collections'],
    queryFn: ({ signal }) => fetchCollections({ signal }),
  });

  let content;

  if (isPending) {
    content = <LoadingIndicator />;
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch collections.'} />;
  }

  if (data) {
    content = (
      <div className="flex lg:px-12 mt-4 gap-8 flex-wrap justify-center text-gray-800">
        {data.length > 0 ? (
          data.map((collection: Collection) => <CollectionCard key={collection.id} collection={collection} />)
        ) : (
          <p>No public collections available yet.</p>
        )}
      </div>
    );
  }

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>Collections - Cooking with Friends</title>
        <meta property="og:title" content="Collections - Cooking with Friends" />
        <meta property="og:description" content="Browse collections of recipes created by other users." />
      </Helmet>
      <Section title={`Collections`} wrapperClasses="mx-2">
        {content}
      </Section>
    </>
  );
}
