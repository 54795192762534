import React from 'react';
import { Collection } from '../../types/collection';

export default function CollectionCover({ collection }: { collection: Collection }) {
  const { recipes } = collection;

  let content;

  if (recipes.length === 0) {
    content = <div className="bg-gray-100 opacity-90 object-cover rounded-md w-full h-[calc(20rem+0.5rem)]" />;
  } else if (recipes.length === 1) {
    content = (
      <img
        src={recipes[0]?.image}
        alt={recipes[0]?.title}
        className="bg-skeleton opacity-90 object-cover rounded-md w-full h-[calc(20rem+0.5rem)]"
      />
    );
  } else if (recipes.length === 2) {
    content = (
      <div className="flex gap-2">
        <img
          src={recipes[0]?.image}
          alt={recipes[0]?.title}
          className="bg-skeleton opacity-90 object-cover w-[calc(50%-(0.5rem/2))] rounded-tl-md rounded-bl-md h-[calc(20rem+0.5rem)]"
        />
        {recipes[1] && (
          <img
            src={recipes[1]?.image}
            alt={recipes[1]?.title}
            className="bg-skeleton opacity-90 object-cover w-[calc(50%-(0.5rem/2))] rounded-tr-md rounded-br-md h-[calc(20rem+0.5rem)]"
          />
        )}
      </div>
    );
  } else if (recipes.length === 3) {
    content = (
      <>
        <div className="flex flex-col w-[calc(50%-(0.5rem/2))] gap-2">
          <img
            src={recipes[0]?.image}
            alt={recipes[0]?.title}
            className="bg-skeleton opacity-90 object-cover rounded-tl-md rounded-bl-md h-[calc(20rem+0.5rem)]"
          />
        </div>
        <div className="flex flex-col w-[calc(50%-(0.5rem/2))] gap-2">
          {recipes[1] && (
            <img
              src={recipes[1]?.image}
              alt={recipes[1]?.title}
              className="bg-skeleton opacity-90 object-cover rounded-tr-md h-40"
            />
          )}
          {recipes[2] && (
            <img
              src={recipes[2]?.image}
              alt={recipes[2]?.title}
              className="bg-skeleton opacity-90 object-cover rounded-br-md h-40"
            />
          )}
        </div>
      </>
    );
  } else {
    content = (
      <>
        <div className="flex flex-col w-[calc(50%-(0.5rem/2))] gap-2">
          <img
            src={recipes[0]?.image}
            alt={recipes[0]?.title}
            className="bg-skeleton opacity-90 object-cover rounded-tl-md h-48"
          />
          {recipes[1] && (
            <img
              src={recipes[1]?.image}
              alt={recipes[1]?.title}
              className="bg-skeleton opacity-90 object-cover rounded-bl-md h-32"
            />
          )}
        </div>
        <div className="flex flex-col w-[calc(50%-(0.5rem/2))] gap-2">
          {recipes[2] && (
            <img
              src={recipes[2]?.image}
              alt={recipes[2]?.title}
              className="bg-skeleton opacity-90 object-cover rounded-tr-md h-32"
            />
          )}
          {recipes[3] && (
            <img
              src={recipes[3]?.image}
              alt={recipes[3]?.title}
              className="bg-skeleton opacity-90 object-cover rounded-br-md h-48"
            />
          )}
        </div>
      </>
    );
  }

  return <figure className="w-full flex flex-wrap justify-center items-center gap-2">{content}</figure>;
}
